import { createResponseSchema } from "@/shared";
import { SectionSchema } from "../schema/schema";
import { z } from "zod";

const SectiosResponseSchema = z.object({
  sections: z.array(SectionSchema),
});

const SectioResponseSchema = z.object({
  section: SectionSchema,
});

const SectioRemoveResponseSchema = z.object({
  message: z.string(),
});

export const SectiosResponseDto = createResponseSchema(SectiosResponseSchema);
export const SectioResponseDto = createResponseSchema(SectioResponseSchema);
export const SectioRemoveResponseDto = createResponseSchema(SectioRemoveResponseSchema);
