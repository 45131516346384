import { createStore } from "@/shared/service/create-store";
import { TaskInitState } from "../schema/schema";


const initState: TaskInitState = {
  tasksList: [],
  currentTask: null,
  currentQuestion: null,
  loadingSubmit: false,
  currentCount: 0,
  totalCount: 0,
};

export const taskStore = createStore({ initState });

export type taskStoreType = typeof taskStore;