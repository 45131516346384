import { FetchService } from "@/shared";
import { languagesEndpoints, type LanguageEndpointsType } from "./endpoints";
import * as dto from "../dto";


export class LanguageRepository {
 private readonly endpoints: LanguageEndpointsType;
 
  constructor(private readonly fethService: FetchService) {   
    this.endpoints = languagesEndpoints;
  }

  public fethLanguages(params: dto.GetLanguagesParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService.get({ url, params })
    .then(data => dto.LanguagesResponseDto.parseAsync(data));
  }

  public fethLanguage(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService.get({ url })
    .then(data => dto.LanguageResponseDto.parseAsync(data));
  }

  public fetchCreateLanguage(payload : dto.CreateLanguagePayload) {
    const url = this.endpoints.create;

    return this.fethService.post({ url, payload })
    .then(data => dto.LanguageResponseDto.parseAsync(data));
  }

  public fethEditLanguage({ id, payload }: { id: string, payload: dto.EditLanguageDto }) {
    const url = this.endpoints.edit(id);

    return this.fethService.put({ url, payload })
    .then(data => dto.LanguageResponseDto.parseAsync(data));
  }
  
  public fethRemoveLanguage(id: string) {
    const url = this.endpoints.remove(id);
    
    return this.fethService.delete({ url })
    .then(data => dto.LanguageRemoveResponseDto.parseAsync(data));
  }

}
