import { z } from "zod";

export const OrderSchema = z.object({
  id: z.number(),
  name: z.string(),
  course_id: z.number(),
  phone: z.string(),
  email: z.string().email(),
  viewed: z.number(),
  payment_method: z.string().nullable().optional(),
  course_name: z.string().optional(),
});

const OrdersInitStateSchema = z.object({
  ordersList: z.array(OrderSchema),
  currentOrder: OrderSchema.nullable(),
  loadingSubmit: z.boolean(),
  totalCount: z.number(),
  newOrderCount: z.number().nullable(),
});

export type OrderModel = z.infer<typeof OrderSchema>;
export type OrdersInitState = z.infer<typeof OrdersInitStateSchema>;
