import { FetchService } from "@/shared";
import { OrdersRepository } from "../repository/repository";
import * as dto from "../dto";


export class OrdersService {
  private readonly repository: OrdersRepository;

  constructor() {
    this.repository = new OrdersRepository(new FetchService());
  }

  public fetchOrders(payload: Record<string, string>) {
    return this.repository.fetchOrders(payload);
  }

  public fetchCreateOrder(payload: dto.CreateOrderPayloadDto) {
    return this.repository.fetchCreateOrder(payload);
  }

  public fetchOrder(id: string) {
    return this.repository.fetchOrder(id);
  }

  public fetchOrderNewCount() {
    return this.repository.fetchOrderNewCount();
  }

  public fetchRemoveOrder(id: string) {
    return this.repository.fetchRemoveOrder(id);
  }
}
