import { FetchService } from "@/shared";
import { TemplateRepository } from "../repository/repository";
import * as dto from "../dto";

export class TemplateService {
  private readonly repository: TemplateRepository;

  constructor() {
    this.repository = new TemplateRepository(new FetchService());
  }

  public fethGetTemplates(params: dto.GetTemplatesParamsDto) {
    return this.repository.fethGetTemplates(params);
  }

  public fethTemplate(id: string) {
    return this.repository.fethTemplate(id);
  }

  public fethTemplateKeys() {
    return this.repository.fethTemplateKeys();
  }

  public fetchCreateTemplate(payload: dto.CreateTemplatePayloadDto) {
    return this.repository.fetchCreateTemplate(payload);
  }

  public fethEditTemplate(payload: { id: string; payload: dto.CreateTemplatePayloadDto }) {
    return this.repository.fethEditTemplate(payload);
  }

  public fethRemoveTemplate(id: string) {
    return this.repository.fethRemoveTemplate(id);
  }
}
