export type StatisticsEndpointsType = {
  getSatisticEvents: string;
  createEvent: string;
  money: string;
  certificates: string;
  getStatisticInfo: (id: string) => string;
  editStatisticEvent: (id: string) => string;
  removeStatisticEvetn: (id: string) => string;
};

export const statisticsEndpoints: StatisticsEndpointsType = {
  getSatisticEvents: "statistics/events/statistics",
  createEvent: "statistics/events",
  getStatisticInfo: (id: string) => `statistics/events/${id}`,
  editStatisticEvent: (id: string) => `statistics/events/${id}`,
  removeStatisticEvetn: (id: string) => `statistics/events/${id}`,
  money: "statistics/money",
  certificates: "statistics/certificates",
};
