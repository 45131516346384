import {
  METODATA_TITLES,
  METODATA_TITLES_DINAMIC,
} from "../constants/metodata";
import z from "zod";

const envVariablesSchema = z.object({
  BACKEND_URL: z.string(),
  APP_MODE: z.string(),
  REACT_APP_VERSION: z.string(),
  REACT_APP_STATUS: z.string(),
});

const ENV_VARIABLES = envVariablesSchema.parse({
  BACKEND_URL: "https://api.edu-adinex.online/",
  APP_MODE:"prod",
  REACT_APP_VERSION,
  REACT_APP_STATUS,
});

export const CONFIG_APP = {
  METODATA_TITLES,
  METODATA_TITLES_DINAMIC,
  API_ENDPOINT: ENV_VARIABLES.BACKEND_URL,
  ACCESS_TOKEN_LOCALE_STORAGE: "ACCESS_TOKEN_PLATFORMA_ADMIN",
  REFRESH_TOKEN_LOCALE_STORAGE: "REFRESH_TOKEN_PLATFORMA_ADMIN",
  ACCESS_TOKEN_COOKIE: "ACCESS_TOKEN_PLATFORMA_ADMIN",
  REFRESH_TOKEN_COOKIE: "REFRESH_TOKEN_PLATFORMA_ADMIN",
  PATH_UPLOADS_AVATARS: `${ENV_VARIABLES.BACKEND_URL}/public/uploads/avatars/`,
  PATH_UPLOADS_IMAGES: `${ENV_VARIABLES.BACKEND_URL}/public/uploads/images/`,
  PATH_UPLOADS_PHOTO: `${ENV_VARIABLES.BACKEND_URL}/public/uploads/photos/`,
  PATH_UPLOADS_MATERIALS: `${ENV_VARIABLES.BACKEND_URL}/public/uploads/additional-materials/`,
  START_PAGE_AFTER_LOGIN: "dashboard",
  REACT_APP_VERSION: ENV_VARIABLES.REACT_APP_VERSION,
  REACT_APP_STATUS: ENV_VARIABLES.REACT_APP_STATUS,
  APP_MODE: ENV_VARIABLES.APP_MODE,
} as const;
