import { LayoutAdapter } from "../store/adapter";

export class LayoutController {
  layoutAdapter: LayoutAdapter;
  constructor() {
    this.layoutAdapter = new LayoutAdapter();
  }

  public activeSideMenu() {
    return this.layoutAdapter.setSideMenuOpen();
  }

  public hideSideMenu() {
    return this.layoutAdapter.setSideMenuClose();
  }
}

export const layoutController = new LayoutController();
