import { SectionModel } from "../schema/schema";
import { sectionsStore } from "./store";


export class SectionsAdapter {

  constructor() {}

  public setSections(sectionsList: SectionModel[]) {
    return sectionsStore.setState({ sectionsList });
  }

  public setSection(currentSections: SectionModel) {
    return sectionsStore.setState({ currentSections });
  }

  public filterCourses(id: number) {
    const sectionsList = sectionsStore.getState().sectionsList;

    return sectionsStore.setState({ sectionsList: sectionsList.filter(item => item.id !== id) });
  }

  public setLoadingSubmit() {
    return sectionsStore.setState({ loadingSubmit: true });
  }

  public resetLoadingSubmit() {
    return sectionsStore.setState({ loadingSubmit: false });
  }

  public setCurrentId(currentId: number) {
    return sectionsStore.setState({ currentId});
  }

  public resetCurrentId() {
    return sectionsStore.setState({ currentId: null });
  }

}


