import { createStore } from "@/shared/service/create-store";
import { SectionsInitState } from "../schema/schema";

const initState: SectionsInitState = {
  sectionsList: [],
  currentSections: null,
  currentId: null,
  loadingSubmit: false,
};

export const sectionsStore = createStore({ initState });
