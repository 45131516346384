import { createResponseSchema } from "@/shared";
import { UserSchema, UserTableListSchema } from "../schema/schema";
import { z } from "zod";

const UsersResponseSchema = z.object({
  limit: z.number(),
  page: z.number(),
  currentCount: z.number(),
  totalCount: z.number(),
  users: z.array(UserTableListSchema),
});

const UserResponseSchema = z.object({
  user: UserSchema,
});
const CurrentUserResponseSchema = z.object({
  user: UserSchema,
  activeCourses: z.number(),
  passedCourses: z.number(),
});

const UserRemoveResponseSchema = z.object({
  message: z.string(),
});

const RoleResponseSchema = z.object({
  roles: z.string().array().nonempty(),
});

export const CurrentUserResponseDto = createResponseSchema(
  CurrentUserResponseSchema
);
export const UsersResponseDto = createResponseSchema(UsersResponseSchema);
export const UserResponseDto = createResponseSchema(UserResponseSchema);
export const UserRemoveResponseDto = createResponseSchema(
  UserRemoveResponseSchema
);
export const RolesResponseDto = createResponseSchema(RoleResponseSchema);
