import { z } from "zod";

export const CreateOrderSchema = z.object({
  name: z.string(),
  phone: z.string(),
  email: z.string().email(),
  course_id: z.number(),
  payment_method: z.string(),
});

export type CreateOrderPayloadDto = z.infer<typeof CreateOrderSchema>;
