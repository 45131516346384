import { createResponseSchema } from "@/shared";
import { KeySchema, TemplateSchema } from "../schema/schema";
import { z } from "zod";

const TemplatesResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  templates: z.array(TemplateSchema),
});

const TemplateResponseSchema = z.object({
  template: TemplateSchema,
});

const TemplateRemoveResponseSchema = z.object({
  message: z.string(),
});

const KeysResponseSchema = z.object({
  keys: z.array(KeySchema),
});

export const TemplatesResponseDto = createResponseSchema(TemplatesResponseSchema);
export const TemplateResponseDto = createResponseSchema(TemplateResponseSchema);
export const TemplateRemoveResponseDto = createResponseSchema(TemplateRemoveResponseSchema);
export const KeysResponseDto = createResponseSchema(KeysResponseSchema);
