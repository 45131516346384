import { FetchService } from "@/shared";
import { taskEndpoints, type TaskEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class TaskRepository {
  private readonly endpoints: TaskEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = taskEndpoints;
  }

  public fetchTasks(id: string) {
    const url = this.endpoints.getList(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.TasksResponseDto.parseAsync(data));
  }

  public fetchCreateVideoLink({
    id,
    payload,
  }: {
    id: string;
    payload: dto.CreateVideoLinkDto;
  }) {
    const url = this.endpoints.createVideoLink(id);

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.CurrentTaskResponseDto.parseAsync(data));
  }

  public fetchEditVideoLink({
    id,
    payload,
  }: {
    id: string;
    payload: dto.CreateVideoLinkDto;
  }) {
    const url = this.endpoints.editVideoLink(id);

    return this.fethService.put({ url, payload });
    // .then(data => dto.CurrentTaskResponseDto.parseAsync(data));
  }

  public fetchRemoveTask(id: string) {
    const url = this.endpoints.remove(id);

    return this.fethService
      .delete({ url })
      .then((data) => dto.RemoveTaksResponseDto.parseAsync(data));
  }

  public fetchCreateQuestion({ id, payload }: { id: string, payload: dto.CreateQuestionDto }) {
    const url = this.endpoints.createQuestion(id);

    return this.fethService.post({ url, payload })
    .then(data => dto.CreateQuestonResponseDto.parseAsync(data));
  }

  public fetchCurrentQuestion(id: string) {
    const url = this.endpoints.getQuestion(id);

    return this.fethService.get({ url })
    .then(data => dto.CurrentQuestonResponseDto.parseAsync(data));
  }

  public fetchEditQuestion({
    id,
    payload,
  }: {
    id: string;
    payload: dto.CreateQuestionDto;
  }) {
    const url = this.endpoints.editQuestion(id);

    return this.fethService.put({ url, payload });
    // .then(data => dto.CurrentTaskResponseDto.parseAsync(data));
  }

}
