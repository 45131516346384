import { FetchService } from "@/shared";
import { SectionsRepository } from "../repository/repository";
import * as dto from "../dto";


export class SectionsService {
  private readonly repository: SectionsRepository;

  constructor() {
    this.repository = new SectionsRepository(new FetchService());
  }

  public createSection(payload: dto.CreateSectioDto) {
    return this.repository.fecthCreateSection(payload);
  }

  public getSections(id: string) {
    return this.repository.fechtGetSections(id);
  }

  public getCurrentSection(id: string) {
    return this.repository.fechtCurrentSection(id);
  }

  public editSection({ id, payload }: { id: string, payload: dto.EditSectioDto }) {
    return this.repository.fechtEditSection({ id, payload });
  }

  public removeSection(id: string) {
    return this.repository.fechtRemoveSection(id);
  }

}
