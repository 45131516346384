import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { motion } from "framer-motion";
import MenuSvg from "../../../../public/assets/svg/menu-svgrepo-com.svg";
import InputSvg from "../../../../public/assets/svg/input-svgrepo-com.svg";
import { layoutController, layoutStore } from "@/shared";
import { authController } from "@/entities";

import styles from "./LayoutHeader.module.scss";

type Props = {
  isLoginPage: boolean;
};

const LayoutHeader = ({ isLoginPage }: Props) => {
  const isOpenMenu = layoutStore((state) => state.sideBarMenuState);
  const navigate = useNavigate();

  const handleToggleMenu = () => {
    if (isOpenMenu) {
      return layoutController.hideSideMenu();
    }
    return layoutController.activeSideMenu();
  };

  const handleLogout = () => {
    authController.logout();
    navigate("/");
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerLeftSide}>
        <motion.img
          src="/assets/logo/logo-header.png"
          alt="App logo"
          width={60}
          height={60}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 0.3,
            type: "just",
          }}
        />
        <motion.h1
          className={styles.titleProject}
          initial={{ x: -30, opacity: 0, scale: 0.3 }}
          animate={{ x: 0, opacity: 1, scale: 1, }}
          transition={{
            delay: 0.5,
            type: "spring",
          }}
        >
          Платформа обучения
        </motion.h1>
      </div>

      <div>
        {!isLoginPage && (
          <>
            <IconButton color="inherit" size="large" onClick={handleLogout}>
              <InputSvg width={26} height={26} color="white" />
            </IconButton>
            <IconButton
              className={styles.buttonMenu}
              color="inherit"
              onClick={handleToggleMenu}
              size="large"
            >
              <MenuSvg width={20} height={20} color="white" />
            </IconButton>
          </>
        )}
      </div>
    </header>
  );
};

export { LayoutHeader };
