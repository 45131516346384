import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { QuestionSchema, TaskSchema } from "../schema/schema";

const TasksResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  tasks: z.array(TaskSchema),
});

const RemoveTaksResponseSchema = z.object({
  message: z.string(),
})

const CreateQuestonResponseSchema = z.object({
  answers: z.array(z.object({
    answer_text: z.string(),
    id: z.number(),
    is_correct: z.number(),
    question_id: z.number(),
  })),
  question: z.object({
    id: z.number(),
    question_text: z.string(),
  }),
  tasks: TaskSchema,
})




export const CurrentTaskResponseDto = createResponseSchema(z.object({ tasks: TaskSchema}));

export const TasksResponseDto = createResponseSchema(
  TasksResponseSchema
);
export const RemoveTaksResponseDto = createResponseSchema(
  RemoveTaksResponseSchema
);

export const CreateQuestonResponseDto = createResponseSchema(
  CreateQuestonResponseSchema
);
export const CurrentQuestonResponseDto = createResponseSchema(
  QuestionSchema
);

