import { createStore } from "@/shared/service/create-store";
import { LanguagesInitState } from "../schema/schema";


const initState: LanguagesInitState = {
  languagesList: [],
  currentLanguage: null,
  loadingSubmit: false,
  currentCount: 0,
};

export const languagesStore = createStore({ initState });

export type languagesStoreType = typeof languagesStore;