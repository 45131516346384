import { motion, AnimatePresence } from "framer-motion";
import { Loader } from "@/shared";
import { loadingStore } from "@/shared/service/loading";

import styles from "./LoadingOverlay.module.scss";

const LoadingOverlay = () => {
  const fetchLoading = loadingStore((state) => state.fetchloading);

  const animation = {
    visible: { opacity: 1, transition: { type: "spring" } },
    hidden: { opacity: 0 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatePresence>
      {fetchLoading && (
        <motion.section
          animate="visible"
          initial="hiden"
          variants={animation}
          exit='exit'
          className={styles.root}
        >
          <Loader />
        </motion.section>
      )}
    </AnimatePresence>
  );
};

export { LoadingOverlay };
