export type OrdersEndpointsType = {
  getAll: string;
  create: string;
  newOrdersCount: string;
  getOne: (id: string) => string;
  edit: (id: string) => string;
  remove: (id: string) => string;
};

export const OrdersEndpoints: OrdersEndpointsType = {
  getAll: "orders",
  create: "orders",
  newOrdersCount: "orders/new",
  getOne: (id: string) => `orders/${id}`,
  edit: (id: string) => `orders/${id}`,
  remove: (id: string) => `orders/${id}`,
};
