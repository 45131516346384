import { z } from "zod";

export const DialogSchema = z.object({
  message: z.array(z.object({
    created_at: z.string(),
    date: z.string(),
    deletedForUser: z.unknown().nullable(),
    id: z.number(),
    message: z.string(),
    receiver_id: z.number(),
    user_id: z.number(),
    viewed: z.number(),
  })),

  user: z.object({
    avatar: z.string().nullable(),
    fio: z.string().nullable(),
    id: z.number(),
    username: z.string(),
  }).optional(),
});

export const MessageSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  receiver_id: z.number(),
  viewed: z.number(),
  userId: z.number().nullable(),
  message: z.string(),
  date: z.string(),
  username: z.string().nullable(),
  fio: z.string().nullable(),
  avatar: z.string().nullable(),
  deletedForUser: z.unknown().nullable(),
  created_at: z.string(),
});

const MessageInitStateSchema = z.object({
  messages: z.array(MessageSchema),
  currentMessage: z.array(MessageSchema),
  dialogs: z.array(DialogSchema),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  unreadCount: z.number().nullable(),
});

export type DialogSchemaModel = z.infer<typeof DialogSchema>;
export type MessageModel = z.infer<typeof MessageSchema>;
export type MessageInitState = z.infer<typeof MessageInitStateSchema>;
