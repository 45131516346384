import { z } from "zod";

export const QuestionSchema = z.object({
  answeres: z.array(z.object({
    answer_text: z.string(),
    answer_id: z.number(),
    is_coorect: z.number(),
  })),
  id: z.number(),
  question_text: z.string(),
});

export const TaskSchema = z.object({
  id: z.number(),
  name: z.string(),
  position: z.number(),
  question_id: z.number().nullable(),
  video_link: z.string().nullable(),
  completed: z.number(),
  section_id: z.number().nullable(),
});

const TaskInitStateSchema = z.object({
  tasksList: z.array(TaskSchema),
  currentTask: TaskSchema.nullable(),
  currentQuestion: QuestionSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  totalCount: z.number(),
});

export type QuestionModel = z.infer<typeof QuestionSchema>;
export type TaskModel = z.infer<typeof TaskSchema>;
export type TaskInitState = z.infer<typeof TaskInitStateSchema>;
