import { FetchService } from "@/shared";
import { LogsRepository } from "../repository/repository";
import * as dto from "../dto";



export class LogsService {
  private readonly repository: LogsRepository;

  constructor() {
    this.repository = new LogsRepository(new FetchService());
  }

  public fetchFrontendLogs(payload: dto.FrongendLogsParamsDto) {
    return this.repository.fetchFrontendLogs(payload);
  }

  public fetchCreateFrontendLog(payload: dto.CreateFrontendLogsDto) {
    return this.repository.fetchCreateFrontendLog(payload);
  }

  public fetchRemoveFrontendLog() {
    return this.repository.fetchRemoveFrontendLog();
  }

  public fetchLogs(payload: dto.LogsParamsDto) {
    return this.repository.fetchLogs(payload);
  }

  public fetchRemoveLogs() {
    return this.repository.fetchRemoveLogs();
  }

}
