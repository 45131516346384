import { TemplateService } from "../service/service";
import { TemplateAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";


class TemplateController {
  constructor(
    private readonly templateService: TemplateService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: TemplateAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getTemplates(params: dto.GetTemplatesParamsDto) {
    this.loadingController.activeFetchLoading();
    this.templateService
      .fethGetTemplates(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setTemplates(response.data.templates);

          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getCurrentTemplate(id: string | undefined) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.loadingController.activeFetchLoading();
    this.templateService
      .fethTemplate(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentTemplate(response.data.template);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getKeys() {
    this.loadingController.activeFetchLoading();
    this.templateService
      .fethTemplateKeys()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setKeys(response.data.keys);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public createTemplate(payload: dto.CreateTemplatePayloadDto) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.templateService
        .fetchCreateTemplate(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Шаблон успешно создан",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public editTemplate(payload: { id: string; payload: dto.CreateTemplatePayloadDto }) {
    this.sendErrorGetID(payload.id);
    if (!payload.id) return;

    this.storeAdapter.setLoadingSubmit();
    this.templateService
      .fethEditTemplate(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentTemplate(response.data.template);
          this.notificationService.activeNotification({
            status: "success",
            message: "Шаблон успешно обновлен",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public removeTemplate(id: string) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.templateService
      .fethRemoveTemplate(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);

          this.notificationService.activeNotification({
            status: "success",
            message: "Шаблон успешно удален",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const templateController = new TemplateController(
  new TemplateService(),
  new NotificationService(),
  new TemplateAdapter(),
  new LoadingController()
);
