import { FetchService } from "@/shared";
import { OrdersEndpoints, type OrdersEndpointsType } from "./endpoints";
import * as dto from "../dto";


export class OrdersRepository {
 private readonly endpoints: OrdersEndpointsType;
 
  constructor(private readonly fetchService: FetchService) {   
    this.endpoints = OrdersEndpoints;
  }

  public fetchOrders(params: Record<string, string>) {
    const url = this.endpoints.getAll;

    return this.fetchService.get({ url, params })
    .then((data) => dto.OrdersResponseDto.parseAsync(data));
  }

  public fetchCreateOrder(payload: dto.CreateOrderPayloadDto) {
    const url = this.endpoints.create;

    return this.fetchService.post({ url, payload })
    // .then((data) => dto.OrdersResponseDto.parseAsync(data));
  }
  
  public fetchOrder(id: string) {
    const url = this.endpoints.getOne(id);
    
    return this.fetchService.get({ url })
    .then((data) => dto.OrderResponseDto.parseAsync(data));
  }

  public fetchOrderNewCount() {
    const url = this.endpoints.newOrdersCount;
    
    return this.fetchService.get({ url })
    .then((data) => dto.NewOrdersCountResponseDto.parseAsync(data));
  }

  
  public fetchRemoveOrder(id: string) {
    const url = this.endpoints.remove(id);
    
    return this.fetchService.delete({ url })
    .then((data) => dto.OrderRemoveResponseDto.parseAsync(data));
  }

}
