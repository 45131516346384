import { FetchService } from "@/shared";
import { FeedbackRepository } from "../repository/repository";


export class FeedbackService {
  private readonly repository: FeedbackRepository;

  constructor() {
    this.repository = new FeedbackRepository(new FetchService());
  }

  public fetchFeedbacks(payload: { page: string; limit: string }) {
    return this.repository.fethFeedbacks(payload);
  }

  public fetchFeedback(id: string) {
    return this.repository.fethFeedback(id);
  }

  public fetchEditFeedback(payload: { id: string; isChecked: boolean }) {
    return this.repository.fethEditFeedback(payload);
  }

  public fetchFeedbackNewCount() {
    return this.repository.fetchFeedbackNewCount();
  }

  public fetchRemoveFeedback(id: string) {
    return this.repository.fethRemoveFeedback(id);
  }
}
