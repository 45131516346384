import { z } from "zod";

const videoLinkRegex = /^(http|https|ftp):/;

export const CreateVideoLinkSchema = z.object({
  name: z.string().min(1, { message: "Введите название" }),
  video_link: z.string().refine(value => videoLinkRegex.test(value), {
    message: "Введите действительную ссылку на видео",
 }),
 position: z.number(),
});

export const CreateQuestionSchema = z.object({
  question: z.object({
    question_text: z.string().min(1, { message: 'Введите вопрос' })
  }),
  answers: z.array(z.object({
    answer_text: z.string().min(1, { message: 'Введите ответ' }),
    is_correct: z.boolean(),
  })),
  task: z.object({
    position: z.number(),
    name: z.string().min(1, { message: "Введите заголовок" }),
    course_id: z.number(),
  }),
});


export type CreateVideoLinkDto = z.infer<typeof CreateVideoLinkSchema>;
export type CreateQuestionDto = z.infer<typeof CreateQuestionSchema>;

