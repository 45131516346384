import type { CertificatesModel, EventModel, MoneyModel } from "../schema/schema";
import { statisticsStore, statisticsStoreType } from "./store";


export class StatisticsAdapter {
  public store: statisticsStoreType
  constructor() {
    this.store = statisticsStore;
  }

  public setMoneyStatistics(money: MoneyModel) {
    return this.store.setState({ moneyStatistics: money });
  }

  public setCertificatesStatistics(certificates: CertificatesModel) {
    return this.store.setState({ certificatesStatistics: certificates });
  }

  public setStatistics(statistics: EventModel[]) {
    return this.store.setState({ statisticsEventList: statistics });
  }

  public setCurrentStatistic(statistic: EventModel) {
    return this.store.setState({ currentStatistic: statistic });
  }

  public filterById(id: string) {
    const currentList = this.store.getState().statisticsEventList;

    return this.store.setState({
      statisticsEventList: currentList.filter((item) => item.id !== Number(id)),
    });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ totalCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setCurrentId(id: number) {
    return this.store.setState({ currentId: id });
  }

  public resetCurrentId() {
    return this.store.setState({ currentId: null });
  }
}
