import { FetchService } from "@/shared";
import { SmtpRepository } from "../repository/repository";
import * as dto from "../dto";


export class SmtpService {
  private readonly repository: SmtpRepository;

  constructor() {
    this.repository = new SmtpRepository(new FetchService());
  }

  public fethGetSmpt() {
    return this.repository.fethGetSmpt();
  }

  public fethEditSmtp(payload: dto.EditSmtpPayloadDto) {
    return this.repository.fethEditSmtp(payload);
  }
}
