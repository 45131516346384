import { createStore } from "@/shared/service/create-store";
import { MessageInitState } from "../schema/schema";

const initState: MessageInitState = {
  messages: [],
  currentMessage: [],
  currentCount: 0,
  loadingSubmit: false,
  dialogs: [],
  unreadCount: null,
};

export const messageStore = createStore({ initState });

export type messageStoreType = typeof messageStore;