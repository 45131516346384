import { createResponseSchema } from "@/shared";
import { ContentCourseSchema, CourseSchema, CourseUserSchema } from "../schema/schema";
import { z } from "zod";

const CoursesResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  courses: z.array(CourseSchema),
});

const CourseUsersResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  courses: z.object({
    data: z.array(CourseUserSchema),
    pagination: z.object({
      currentPage: z.number().optional().nullable(),
      from: z.number().optional().nullable(),
      lastPage: z.number().optional().nullable(),
      nextPage: z.number().optional().nullable(),
      perPage: z.number().optional().nullable(),
      prevPage: z.number().nullable().optional().nullable(),
      to: z.number().optional().nullable(),
      total: z.number().optional().nullable(),
    }),
  }),
});

const CourseResponseSchema = z.object({
  course: CourseSchema,
});

const CourseRemoveResponseSchema = z.object({
  message: z.string(),
});

const AddUserCourseResponseSchema = z.object({
  couse: z.number(),
  user: z.number(),
});


const UserContentCoursesResponseSchema = z.array(ContentCourseSchema);

export const CourseUsersResponseDto = createResponseSchema(
  CourseUsersResponseSchema
);
export const CoursesResponseDto = createResponseSchema(CoursesResponseSchema);
export const CourseResponseDto = createResponseSchema(CourseResponseSchema);
export const CourseRemoveResponseDto = createResponseSchema(
  CourseRemoveResponseSchema
);
export const AddUserCourseResponseDto = createResponseSchema(
  AddUserCourseResponseSchema
);
export const UserContentCoursesResponseDto = createResponseSchema(
  UserContentCoursesResponseSchema
);
