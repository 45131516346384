export type MessagesEndpointsType = {
  getMessages: string;
    getDialogs: string;
    getMessagesUser: (id: string) => string;
    removeMessage: (id: string) => string;
    createMessage: string;
    removeMessageDialog: (id: string) => string;
    getUnreadCoutn: string;
};

export const messageEndpoints = {
  getMessages: "messages",
  getDialogs: "messages/dialogs",
  getMessagesUser: (id: string) => `messages/${id}`,
  removeMessage: (id: string) => `messages/${id}`,
  createMessage: 'messages',
  removeMessageDialog: (id: string) => `messages/dialog/${id}`,
  getUnreadCoutn: 'messages/unread',
};
