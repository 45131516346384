import { FetchService } from "@/shared";
import { LanguageRepository } from "../repository/repository";
import * as dto from "../dto";


export class LanguageService {
  private readonly repository: LanguageRepository;

  constructor() {
    this.repository = new LanguageRepository(new FetchService());
  }

  public fethLanguages(payload: dto.GetLanguagesParamsDto) {
    return this.repository.fethLanguages(payload);
  }

  public fethLanguage(id: string) {
    return this.repository.fethLanguage(id);
  }

  public fetchCreateLanguage(payload: dto.CreateLanguagePayload) {
    return this.repository.fetchCreateLanguage(payload);
  }

  public fethEditLanguage(payload: { id: string, payload: dto.EditLanguageDto }) {
    return this.repository.fethEditLanguage(payload);
  }

  public fethRemoveLanguage(id: string) {
    return this.repository.fethRemoveLanguage(id);
  }
}
