import { z } from "zod";

export const EditUserSchema = z.object({
  username: z.string().min(1, { message: "Введите логин" }),
  gender: z.string(),
  email: z.string().email("Неверный формат почты"),
  role: z.string().min(1, { message: "Выберите роль" }),
  fio: z.string().min(1, { message: "Введите ФИО" }),
  organisation: z.string().optional(),
  position: z.string().optional(),
  phone: z
    .string()
    .min(10, { message: "Введите не менее 10 символов" })
    .max(15, { message: "Введите  менее 15 символов" })
    .optional()
    .or(z.literal("")),
});

export const CreateUserSchema = EditUserSchema.extend({
  password: z.string().min(8, { message: "Введите не менее 8 символов" }),
  course_id: z.number().min(1),
  price: z.number().or(z.string()),
  duration: z.number().optional(),
});

export const EditAddCourseSchema = z.object({
  course_id: z.number().min(1),
  price: z.number().or(z.string()),
  duration: z.number().optional(),
});

export const EditUserPasswordSchema = z.object({
  password: z.string().min(8, { message: "Введите не менее 8 символов" }),
});

export type CreateUsersPayloadDto = z.infer<typeof CreateUserSchema>;
export type EditUserPayloadDto = z.infer<typeof EditUserSchema>;
export type EditUserPasswordPayloadDto = z.infer<typeof EditUserPasswordSchema>;
