import { z } from "zod";

export const CreateCoursesSchema = z.object({
  coursesName: z.string().min(1, { message: "Введите название курса" }),
  description: z.string().min(1, { message: "Введите описание курса" }),
});

export const AddUserCoursePayloadSchema = z.object({
  course_id: z.number(),
  user_id: z.number(),
  duration: z.number(),
  price: z.number(),
});


export const EditCoursesSchema = CreateCoursesSchema.extend({});

export type AddUserCoursePayloadDto = z.infer<typeof AddUserCoursePayloadSchema>;
export type CreateCoursesDto = z.infer<typeof CreateCoursesSchema>;
export type EditCoursesDto = z.infer<typeof EditCoursesSchema>;
