import { FetchService } from "@/shared";
import { statisticsEndpoints, type StatisticsEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class StatisticsRepository {
  private readonly endpoints: StatisticsEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = statisticsEndpoints;
  }

  public fethStatisticsMoney(params: { startDate: string }) {
    const url = this.endpoints.money;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.MoneyResponseDto.parseAsync(data));
  }
  public fethStatisticCertificates(params: { startDate: string }) {
    const url = this.endpoints.certificates;

    return this.fethService.get({ url, params })
    .then(data => dto.CertificatesResponseDto.parseAsync(data));
  }

  public fethStatisticsEvents(params: dto.EventsParamsDto) {
    const url = this.endpoints.getSatisticEvents;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.EventsResponseDto.parseAsync(data));
  }

  public fethStatisticsEvent(id: string) {
    const url = this.endpoints.getStatisticInfo(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.EventResponseDto.parseAsync(data));
  }

  public fethRemoveStatisticsEvent(id: string) {
    const url = this.endpoints.removeStatisticEvetn(id);

    return this.fethService
      .delete({ url })
      .then((data) => dto.EventRemoveResponseDto.parseAsync(data));
  }

  public fethCreateStatisticsEvent(payload: dto.CreateEventDto) {
    const url = this.endpoints.createEvent;

    return this.fethService
      .post({
        url,
        payload,
      })
      .then((data) => dto.EventResponseDto.parseAsync(data));
  }

  public fethEditStatisticsEvent(id: string, payload: dto.CreateEventDto) {
    const url = this.endpoints.editStatisticEvent(id);

    return this.fethService
      .put({
        url,
        payload,
      })
      .then((data) => dto.EventResponseDto.parseAsync(data));
  }
}
