import { FetchService } from "@/shared";
import { TaskRepository } from "../repository/repository";
import * as dto from "../dto";


export class TaskService {
  private readonly repository: TaskRepository;

  constructor() {
    this.repository = new TaskRepository(new FetchService());
  }

  public fetchTasks(id: string) {
    return this.repository.fetchTasks(id);
  }

  public fetchCreateVideoLink(payload : { id: string, payload: dto.CreateVideoLinkDto }) {
    return this.repository.fetchCreateVideoLink(payload);
  }
  
  public fetchEditVideoLink(payload : { id: string, payload: dto.CreateVideoLinkDto }) {
    return this.repository.fetchEditVideoLink(payload);
  }

  public fetchRemoveTask(id: string) {
    return this.repository.fetchRemoveTask(id);
  }

  public fetchCreateQuestion(payload: { id: string, payload: dto.CreateQuestionDto }) {
    return this.repository.fetchCreateQuestion(payload);
  }

  public fetchCurrentQuestion(id: string) {
    return this.repository.fetchCurrentQuestion(id);
  }

  public fetchEditQuestion(payload: { id: string, payload: dto.CreateQuestionDto }) {
    return this.repository.fetchEditQuestion(payload);
  }

}
