import { FetchService } from "@/shared";
import { sectionsEndpoints, type SectionsEndpointsType } from "./endpoints";
import * as dto from "../dto";


export class SectionsRepository {
 private readonly endpoints: SectionsEndpointsType;
 
  constructor(private readonly fethService: FetchService) {   
    this.endpoints = sectionsEndpoints;
  }

  public fecthCreateSection(payload: dto.CreateSectioDto) {
    const url = this.endpoints.createSection;

    return this.fethService.post({ url, payload })
    .then(data => dto.SectioResponseDto.parseAsync(data));
  }

  public fechtGetSections(courses_id: string) {
    const url = this.endpoints.getSections;

    const params: { course_id?: string } = {}

    if (courses_id) {
      params.course_id = courses_id;
    }

    return this.fethService.get({ url, params })
    .then(data => dto.SectiosResponseDto.parseAsync(data));
  }

  public fechtCurrentSection(id: string) {
    const url = this.endpoints.getSection(id);

    return this.fethService.get({ url })
    .then(data => dto.SectioResponseDto.parseAsync(data));
  }

  public fechtEditSection({ id, payload }: { id: string, payload: dto.EditSectioDto }) {
    const url = this.endpoints.editSection(id);

    return this.fethService.put({ url, payload })
    .then(data => dto.SectioResponseDto.parseAsync(data));
  }
  
  public fechtRemoveSection( id: string ) {
    const url = this.endpoints.editSection(id);
    
    return this.fethService.delete({ url })
    .then(data => dto.SectioRemoveResponseDto.parseAsync(data));
  }


}
