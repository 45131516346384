import { z } from "zod";

export const FeedbackSchema = z.object({
  id: z.number(),
  creationDate: z.string(),
  email: z.string().email(),
  isChecked: z.number().or(z.boolean()),
  message: z.string(),
  name: z.string(),
});

const UserInitStateSchema = z.object({
  feedbacksList: z.array(FeedbackSchema),
  currentFeedback: FeedbackSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  currentId: z.number().nullable(),
  newFeedbackCount: z.number().nullable(),
  totalCount: z.number(),
});

export type FeedbackModel = z.infer<typeof FeedbackSchema>;
export type FeedbackInitState = z.infer<typeof UserInitStateSchema>;
