import { FetchService } from "@/shared";
import { authEndpoints, type AuthEndpointsType } from "./endpoints";
import * as dto from "../dto";
import { CONFIG_APP } from "@/shared/config";
import { CookieService } from "@/shared/service/cookie/CookieService";

export class AuthRepository {
  private readonly endpoints: AuthEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = authEndpoints;
  }

  public fethAuthorization(payload: dto.AuthorizationDto) {
    const url = this.endpoints.login;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.AuthorizationResponseDto.parseAsync(data));
  }

  public fethRefreshToken() {
    const url = new URL(
      `${CONFIG_APP.API_ENDPOINT}/api/${this.endpoints.refresh}`
    );
    const refreshToken = CookieService.get(CONFIG_APP.REFRESH_TOKEN_COOKIE);

    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => dto.RefreshTokenResponseDto.parseAsync(data));
  }

  public fethProfile() {
    const url = this.endpoints.getProfile;

    return this.fethService
      .get({ url })
      .then((data) => dto.ProfileResponseDto.parseAsync(data));
  }

  public fethChangeAvatar(payload: FormData) {
    const url = this.endpoints.changeAvatar;

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.ProfileResponseDto.parseAsync(data));
  }

  public fethEditProfile(payload: dto.EditProfileAdminDto) {
    const url = this.endpoints.editProfile;

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.ProfileResponseDto.parseAsync(data));
  }

  public fethChangePassword(payload: dto.EditProfileAdminPasswordDto) {
    const url = this.endpoints.changePassword;

    return this.fethService
      .put({
        url,
        payload,
      })
      .then((data) => dto.ProfileResponseDto.parseAsync(data));
  }

  public fethRecoveryPassword(payload: dto.RecoveryPasswordSchemaDto) {
    const url = this.endpoints.recoveryPassword;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.ProfileResponseDto.parseAsync(data));
  }
}
