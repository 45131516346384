import { EmailService } from "../service/service";
import { EmailAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class EmailController {
  constructor(
    private readonly emailService: EmailService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: EmailAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getEmailHistory(params: dto.GetEmailsParamsDto) {
    this.loadingController.activeFetchLoading();
    this.emailService
      .fetchEmailHistorys(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setEmailsList(response.data.emailHistory);
          if (response.data.currentCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch((error) =>
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public removeEmailHistory() {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.emailService
        .fetchRemoveEmails()
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.resetEmails();
            this.notificationService.activeNotification({
              status: "success",
              message: "История успешно удалена",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch((error) =>
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public setUserEmail(payload: dto.SendEmailDto) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.emailService
        .fetchSendUserEmail(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.resetEmails();
            this.notificationService.activeNotification({
              status: "success",
              message: "Почта успешно отправлена",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch((error) =>
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  public setAllUserEmail(payload: dto.SendEmailDto) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.emailService
        .fetchSendAllUserEmail(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.resetEmails();
            this.notificationService.activeNotification({
              status: "success",
              message: "Почта успешно отправлена",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch((error) =>
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
    });
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const emailController = new EmailController(
  new EmailService(),
  new NotificationService(),
  new EmailAdapter(),
  new LoadingController()
);
