import { CONFIG_APP } from "@/shared/config";
import { CoursesService } from "../service/service";
import { CoursesAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import { AddUserCoursePayloadDto } from "../dto";

export class CoursesController {
  constructor(
    private readonly coursesService: CoursesService,
    private readonly notificationService: NotificationService,
    private readonly coursesAdapter: CoursesAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public setCurrentId(id: number) {
    return this.coursesAdapter.setCurrentId(id);
  }

  public resetCurrentId() {
    return this.coursesAdapter.resetCurrentId();
  }

  public getAllCourses(payload: Record<string, string>) {
    this.loadingController.activeFetchLoading();
    this.coursesService
      .getAllCouses(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.coursesAdapter.setCourses(response.data.courses);
          if (response.data.currentCount) {
            this.coursesAdapter.setCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getCourse(id?: string | undefined) {
    return new Promise((resolve) => {
      if (!id) {
        return this.notificationService.activeNotification({
          status: "error",
          message: "Ошибка: ID не найден",
        });
      }

      this.loadingController.activeFetchLoading();
      this.coursesService
        .getCourse(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.coursesAdapter.setCourse(response.data.course);
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch((error) =>
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
        .finally(() => this.loadingController.resetFethLoading());
    });
  }

  public addCourse(
    payload: FormData
  ): Promise<{ id: number; result: string | null }> {
    return new Promise((resolve, reject) => {
      this.coursesAdapter.setLoadingSubmit();

      this.coursesService
        .addCourses(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            const id = response.data.course.id;

            this.notificationService.activeNotification({
              status: "success",
              message:
                "Отлично! Курс успешно создан. Не забудьте добавить задания к этому курсу.",
            });

            return resolve({ result: "success", id });
          } else {
            throw response.message;
          }
        })
        .catch((error) => {
          let errorMessage = error;
          if (errorMessage.includes("Duplicate entry")) {
            errorMessage = "Такой курс уже существует в базе данных";
          }
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${errorMessage}`,
          });
          return reject({ result: "error", id: null });
        })
        .finally(() => this.coursesAdapter.resetLoadingSumbmit());
    });
  }

  public async editCourse(payload: {
    id: string;
    payload: FormData;
  }): Promise<string> {
    let result = null;
    return new Promise((resolve) => {
      this.coursesAdapter.setLoadingSubmit();
      this.coursesService
        .editCouses(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            const image = response.data.course.image;

            result = image
              ? `${CONFIG_APP.PATH_UPLOADS_PHOTO}${image}`
              : "edit";

            this.notificationService.activeNotification({
              status: "success",
              message: "Изменения сохранены",
            });
            this.coursesAdapter.setCourse(response.data.course);
            return resolve(result);
          } else {
            throw response.message;
          }
        })
        .catch((error) => {
          let errorMessage = error;
          if (errorMessage.includes("Duplicate entry")) {
            errorMessage = "Такой курс уже существует в базе данных";
          }
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${errorMessage}`,
          });
        })
        .finally(() => this.coursesAdapter.resetLoadingSumbmit());
    });
  }

  public addUserCourse(payload: AddUserCoursePayloadDto) {
    return new Promise((resolve) => {
      this.coursesAdapter.setLoadingSubmit();

      this.coursesService
        .addUserCourse(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Отлично! Курс успешно добавлен",
            });
          } else {
            throw response.message;
          }
          return resolve(response.status);
        })
        .catch((error) => {
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          });
        })
        .finally(() => this.coursesAdapter.resetLoadingSumbmit());
    });
  }

  public removeCourse({
    id,
    limit,
    page,
  }: {
    id: string;
    page: string;
    limit: string;
  }) {
    this.coursesAdapter.setLoadingSubmit();
    this.coursesService
      .removeCouses(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.coursesAdapter.filterCourses(Number(id));
          this.notificationService.activeNotification({
            status: "success",
            message: "Курс успешно удален",
          });
          this.getAllCourses({ page, limit });
        } else {
          throw response.message;
        }
      })
      .catch((error) =>
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
      )
      .finally(() => this.coursesAdapter.resetLoadingSumbmit());
  }

  public getCourseUsers(
    id: string | undefined,
    params: Record<string, string>
  ) {
    if (!id) {
      return this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID курса не найден",
      });
    }
    this.loadingController.activeFetchLoading();
    this.coursesService
      .fetchGetUsersCourses(id, params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.coursesAdapter.setUserCourses(response.data.courses.data);
          if (response.data.currentCount) {
            this.coursesAdapter.setUserCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getUsersContentCourses(id: string) {
    if (!id) {
      return this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID пользователя не найден",
      });
    }
    this.loadingController.activeFetchLoading();
    return this.coursesService
      .fetchUserContentCourses(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.coursesAdapter.setContentUserCourses(response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }
}

export const coursesController = new CoursesController(
  new CoursesService(),
  new NotificationService(),
  new CoursesAdapter(),
  new LoadingController()
);
