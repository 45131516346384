import { baseFetch } from "./baseApi";

export class FetchService {

  public get({ url, params}: { url: string, params?: Record<string, string>}) {
    return baseFetch({url,  method: 'GET', params })
  }

  public post({ url, payload, params }: {url: string, payload?: object | FormData, params?: Record<string, string> }) {
    return baseFetch({url,  payload, method: 'POST', params })
  }

  public patch({url, payload, params}: {url: string, payload: object , params?: Record<string, string>}) {
    return baseFetch({url, payload, method: 'PATCH', params })
  }

  public put({url, payload, params}: {url: string, payload: object , params?: Record<string, string>}) {
    return baseFetch({url, payload, method: 'PUT', params })
  }

  public delete({ url, params }: {url: string,  params?: Record<string, string>}) {
    return baseFetch({url, method: 'DELETE', params })
  }

  public getBlob({ query, variables }: { query: string, variables?: object }) {
    return baseFetch({ method: 'POST', payload: { query, variables } })
  }

  public graphQl({ query, variables }: { query: string, variables?: object }) {
    return baseFetch({ method: 'POST', payload: { query, variables } })
  }

}
