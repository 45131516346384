export type FeedbackEndpointsType = {
  getAll: string;
  newFeedbackCount: string;
  getInfo: (id: string) => string;
  edit: (id: string) => string;
  remove: (id: string) => string;
}

export const feedbackEndpoints: FeedbackEndpointsType = {
  getAll: "feedback",
  newFeedbackCount: "feedback/new",
  getInfo: (id: string) => `feedback/${id}`,
  edit: (id: string) => `feedback/${id}`,
  remove: (id: string) => `feedback/${id}`,
}