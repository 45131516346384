import { z } from "zod";

export const EmailSchema = z.object({
  id: z.number(),
  key: z.string(),
  status: z.string(),
  message: z.string(),
  email: z.string().email(),
  date: z.string(),
});

const EmailInitStateSchema = z.object({
  emailList: z.array(EmailSchema),
  currentEmail: EmailSchema.nullable(),
  loadingSubmit: z.boolean(),
  totalCount: z.number(),
});

export type EmailModel = z.infer<typeof EmailSchema>;
export type EmailInitState = z.infer<typeof EmailInitStateSchema>;
