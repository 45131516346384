export type LogsEndpointsType = {
  getFrontendLogs: string;
  createFrontendLog: string;
  removeFrontendLogs: string;
  getLogs: string;
  removeLogs: string;
  getLogsWts: string;
};

export const logsEndpoints: LogsEndpointsType = {
  getFrontendLogs: "logs/frontend",
  createFrontendLog: "logs/frontend",
  removeFrontendLogs: "logs/frontend",
  getLogs: "logs",
  removeLogs: "logs",
  getLogsWts: "logs/wts",
};
