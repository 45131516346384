import { FeedbackModel } from "../schema/schema";
import { feedbackStore, type feedbackStoreType } from "./store";


export class FeedbackAdapter {
  public store: feedbackStoreType;
  constructor() {
    this.store = feedbackStore;
  }

  public setFeedbacks(feedbacks: FeedbackModel[]) {
    return this.store.setState({ feedbacksList: feedbacks });
  }

  public setCurrentFeedback(feedback: FeedbackModel) {
    return this.store.setState({ currentFeedback: feedback });
  }

  public filterById(id: string) {
    const feedbacksList = this.store.getState().feedbacksList;

    return this.store.setState({ feedbacksList: feedbacksList.filter(item => item.id !== Number(id)) });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ totalCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setCurrentId(id: number) {
    return this.store.setState({ currentId: id });
  }

  public resetCurrentId() {
    return this.store.setState({ currentId: null });
  }

  public setNewFeedbackCount(newFeedbackCount: number) {
    return this.store.setState({ newFeedbackCount });
  }

}


