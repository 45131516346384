import { TaskService } from "../service/service";
import { TasksAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";
import { TaskModel } from "../schema/schema";

class TaskController {
  constructor(
    private readonly taskService: TaskService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: TasksAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getTasks(id: string) { 
    return new Promise((resolve) => {
    this.loadingController.activeFetchLoading();
    this.taskService
      .fetchTasks(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setTasks(response.data.tasks);

          if (response.data.currentCount) {
            this.storeAdapter.setCurrentCount(response.data.currentCount);
          }

          if (response.data.totalCount) {
            this.storeAdapter.setTotalCount(response.data.totalCount);
          }
        } else {
          throw response.message;
        }
        resolve(response.status);
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading()); 
    })
  }

  public createVideoLink(payload : { id: string, payload: dto.CreateVideoLinkDto }): Promise<{  status: string, id: number  }> {
    return new Promise((resolve) => {
    this.storeAdapter.setLoadingSubmit();
    this.taskService
      .fetchCreateVideoLink(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          if (response.data.tasks) {
            this.storeAdapter.setNewTask(response.data.tasks)
          }
          this.notificationService.activeNotification({
            status: "success",
            message: "Ссылка успешно добавлена",
          });
          return resolve({ status: response.status, id: response.data.tasks.id });
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
    })
  }

  public editVideoLink(payload: { id: string, payload: dto.CreateVideoLinkDto }) {
    return new Promise(resolve => {
      this.sendErrorGetID(payload.id);
      if (!payload.id) return;

      this.storeAdapter.setLoadingSubmit();
      this.taskService
      .fetchEditVideoLink(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          // this.storeAdapter.setTask(response.data.tasks);
          this.notificationService.activeNotification({
            status: "success",
            message: "Ссылка успешно сохранена",
          });
          return resolve(response.status);
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
      })
      }

      public removeTask(id: string) {
        return new Promise((resolve) => {

          this.sendErrorGetID(id);
          if (!id)return;
          
          this.storeAdapter.setLoadingSubmit();
          this.taskService
          .fetchRemoveTask(id)
          .then((response) => {
            if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);
          
          this.notificationService.activeNotification({
            status: "success",
            message: "Задание успешно удалено",
          });
        } else {
          throw response.message;
        }
        resolve(response.status);
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
        )
        .finally(() => this.storeAdapter.resetLoadingSumbmit());
      })
  }

  public createQuestion(payload : { id: string, payload: dto.CreateQuestionDto }) {
    return new Promise((resolve) => {
    this.storeAdapter.setLoadingSubmit();
    this.taskService
      .fetchCreateQuestion(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {

          this.notificationService.activeNotification({
            status: "success",
            message: "Вопрос успешно добавлен",
          });
          
        } else {
          throw response.message;
        }
        return resolve(response.status);
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
    })
  }

  public getQuestion(id: string | undefined) {
  this.sendErrorGetID(id);
  if (!id)return;
  this.storeAdapter.resetCurrentQuestion();

    this.loadingController.activeFetchLoading();
    this.taskService
      .fetchCurrentQuestion(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentQuestion(response.data);
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public editQuestion(payload : { id: string, payload: any }) {
    return new Promise((resolve) => {
    this.storeAdapter.setLoadingSubmit();
    this.taskService
      .fetchEditQuestion(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {

          this.notificationService.activeNotification({
            status: "success",
            message: "Вопрос успешно обновлен",
          });
          
        } else {
          throw response.message;
        }
        return resolve(response.status);
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
    })
  }

  public getMaxPositionInTasks(tasksList: TaskModel[]) {
    let max = 1;
    for (let i = 0; i < tasksList.length; i++) {
      if (max < tasksList[i].position) {
        max = tasksList[i].position;
      }
    }
    return max + 1;
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const taskController = new TaskController(
  new TaskService(),
  new NotificationService(),
  new TasksAdapter(),
  new LoadingController()
);


