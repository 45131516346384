import { FetchService } from "@/shared";
import { messageEndpoints, type MessagesEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class MessageRepository {
  private readonly endpoints: MessagesEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = messageEndpoints;
  }

  public fetchMessagesList(params: dto.GetMessageParamsDto) {
    const url = this.endpoints.getMessages;

    return this.fethService.get({ url, params })
      .then((data) => console.log(data));
      // .then((data) => dto.UsersResponseDto.parseAsync(data));
  }

  public fetchDialogList() {
    const url = this.endpoints.getDialogs;

    return this.fethService.get({ url })
    .then((data) => dto.DialogListResponseDto.parseAsync(data));
  }

  public fetchDialogMessages(id: string) {
    const url = this.endpoints.getMessagesUser(id);

    return this.fethService.get({ url })
    .then((data) => dto.MessagesListResponseDto.parseAsync(data));
  }

  public fetchSendMessages(payload: dto.SendMessageInputSchemaDto) {
    const url = this.endpoints.createMessage;

    return this.fethService.post({ url, payload })
    .then((data) => dto.SendMessageResponseDto.parseAsync(data));
  }

  public fetchRemoveMessages(id: string) {
    const url = this.endpoints.removeMessage(id);

    return this.fethService.delete({ url })
    // .then((data) => dto.RemoveMessageResponseDto.parseAsync(data));
  }

  public fetchUnreadMessageCount() {
    const url = this.endpoints.getUnreadCoutn;

    return this.fethService.get({ url })
    .then((data) => dto.UnreadCoutnResponseDto.parseAsync(data));
  }

}
