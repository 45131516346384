import { createResponseSchema } from "@/shared";
import { EmailSchema } from "../schema/schema";
import { z } from "zod";

const EmailsResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  emailHistory: z.array(EmailSchema),
});

const EmailRemoveResponseSchema = z.object({
  message: z.string(),
});

const SendEmailResponseSchema = z.object({
  message: z.string(),
});

const SendAllUserEmailResponseSchema = z.object({
  result: z.object({
    result: z.number(),
    error: z.number(),
  }),
});

export const EmailsResponseDto = createResponseSchema(EmailsResponseSchema);
export const EmailRemoveResponseDto = createResponseSchema(
  EmailRemoveResponseSchema
);
export const SendEmailResponseDto = createResponseSchema(
  SendEmailResponseSchema
);
export const SendAllUserEmailResponseDto = createResponseSchema(
  SendAllUserEmailResponseSchema
);
