import { z } from "zod";


export const TemplateSchema = z.object({
  id: z.number(),
  comment: z.string(),
  html: z.string(),
  key: z.string(),
  subject: z.string(),
  draft: z.unknown().nullable(),
});

export const KeySchema = z.object({
  id: z.number(),
  key: z.string(),
});

const TemplateInitStateSchema = z.object({
  templates: z.array(TemplateSchema),
  keys: z.array(KeySchema),
  currentTemplate: TemplateSchema.nullable(),
  loadingSubmit: z.boolean(),
  totalCount: z.number(),
});

export type TemplateModel = z.infer<typeof TemplateSchema>;
export type KeyModel = z.infer<typeof KeySchema>;
export type TemplateInitState = z.infer<typeof TemplateInitStateSchema>;
