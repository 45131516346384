import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { redirect } from "react-router-dom";
import * as page from "../../pages";
import { NAV } from "./nav-links";
import { NotFoundPage } from "../../pages/NotFound/NotFound";
import { MainLayout } from "@/widgets/layouts/main-layout/MainLayout";


export const routerApp = createBrowserRouter(
  createRoutesFromElements(
    <> 
    <Route path={`${NAV.MAIN}`} element={<MainLayout/>}>
      <Route index  element={<page.LoginPage/>} />

      <Route path={`${NAV.ACCOUNT}`}  element={<page.AccountPage/>} />
      <Route path={`${NAV.DASHBOARD}`}  element={<page.DashboardPage/>} />

      <Route path={`${NAV.USER_INFO}`}  element={<page.UserInfoPage />} />
      <Route path={`${NAV.USER_EDIT}`}  element={<page.UserEditPage />} />
      <Route path={`${NAV.USER_ADD}`}  element={<page.UserAddPage />} />
      <Route path={`${NAV.USER_LIST}`}  element={<page.UserListPage />} />
     
      <Route path={`${NAV.FEEDBACK_LIST}`}  element={<page.FeedBackListPage />} />
      <Route path={`${NAV.FEEDBACK_EDIT}`}  element={<page.FeedBackEditPage />} />
      
      <Route path={`${NAV.EMAIL_SMTP}`}  element={<page.SmtpPage />} />

      <Route path={`${NAV.EMAIL_TEMPLATES}`}  element={<page.EmailTemplateListPage />} />
      <Route path={`${NAV.EMAIL_CREATE}`}  element={<page.EmailTemplateCreatePage />} />
      <Route path={`${NAV.EMAIL_EDIT}`}  element={<page.EmailTemplateEditPage />} />

      <Route path={`${NAV.EMAIL_HISTORY}`}  element={<page.EmailHistoryListPage />} />

      <Route path={`${NAV.LOGS_LIST}`}  element={<page.AppLogsPage />} />

      <Route path={`${NAV.APP_STATISTICS_EVENTS}`}  element={<page.AppStatisticsEventsListPage />} />
      <Route path={`${NAV.APP_STATISTICS_ADD}`}  element={<page.AppStatisticsEventsAddPage />} />
      <Route path={`${NAV.APP_STATISTICS_EDIT}`}  element={<page.AppStatisticsEventsEditPage />} />

      <Route path={`${NAV.LOGS}`}  element={<page.LogsPage />} />
      <Route path={`${NAV.COMMON_SETTINGS}`}  element={<page.CommonSettingsPage />} />
      <Route path={`${NAV.UPDATE_SYSTEM}`}  element={<page.UpdateSystemPage />} />
      
      <Route path={`${NAV.SETTINGS}`}  element={<page.SettingsPage />} />

      <Route path={`${NAV.LANGUAGE_LIST}`}  element={<page.LanguageListPage />} />
      <Route path={`${NAV.LANGUAGE_ADD}`}  element={<page.LanguageAddPage />} />
      <Route path={`${NAV.LANGUAGE_EDIT}`}  element={<page.LanguageEditPage />} />

      <Route path={`${NAV.PHRASE_LIST}`}  element={<page.PhraseListPage />} />
      <Route path={`${NAV.PHRASE_ADD}`}  element={<page.PhraseAddPage />} />
      <Route path={`${NAV.PHRASE_EDIT}`}  element={<page.PhraseEditPage />} />
    
      <Route path={`${NAV.CONFIGURATION_KEYS}`}  element={<page.ConfigurationKeysListPage />} />
      <Route path={`${NAV.CONFIGURATION_KEYS_ADD}`}  element={<page.ConfigurationKeysAddPage />} />
      <Route path={`${NAV.CONFIGURATION_KEYS_EDIT}`}  element={<page.ConfigurationKeysEditPage />} />
      
      <Route path={`${NAV.NO_PAGE}`}  element={<NotFoundPage />}  loader={ () => redirect("/page-not-found") } />
     {/* ----------------------Template------------------------ */}

    <Route  path={`${NAV.COURSES_LIST}`}   element={<page.CoursesListPage />}/>
    <Route path={`${NAV.COURSES_ADD}`}  element={<page.CoursesAddPage />}  />
    <Route path={`${NAV.COURSES_EDIT}`}  element={<page.CoursesEditPage />} />
    <Route path={`${NAV.COURSES_INFO}`}  element={<page.CoursesInfoPage />} />

    <Route path={`${NAV.TASK_ADD}`}  element={<page.TaskAddPage />} />
    <Route path={`${NAV.TASK_EDIT}`}  element={<page.TaskEditPage />} />
    <Route path={`${NAV.TASK_ADD_LINK}`}  element={<page.TaskAddLinkPage />} />
    <Route path={`${NAV.TASK_EDIT_LINK}`}  element={<page.TaskEditLinkPage />} />

    <Route path={`${NAV.TASK_ADD_QUESTION}`}  element={<page.TaskAddQuestionPage />} />
    <Route path={`${NAV.TASK_EDIT_QUESTION}`}  element={<page.TaskEditQuestionPage />} />
    
    <Route path={`${NAV.MESSAGES_LIST}`}  element={<page.MessagesPage />} />
    <Route path={`${NAV.DIALOG}`}  element={<page.DialogPage />} />

    <Route path={`${NAV.ORDERS_LIST}`}  element={<page.OrdersListPage />} />
    <Route path={`${NAV.ORDERS_INFO}`}  element={<page.OrdersInfoPage />} />
    
    <Route path={`${NAV.CONTENT_RECORD_LIST}`}  element={<page.ContentRecordListPage />} />

    <Route path={`${NAV.CONTENT_RECORD_REVIEWS_ADD}`}  element={<page.ContentRecordRewievsAddPage />} />
    <Route path={`${NAV.CONTENT_RECORD_REVIEWS_EDIT}`}  element={<page.ContentRecordRewievsEditPage />} />
    <Route path={`${NAV.CONTENT_RECORD_QUESTION_ADD}`}  element={<page.ContentRecordQWuestionAddPage />} />
    <Route path={`${NAV.CONTENT_RECORD_QUESTION_EDIT}`}  element={<page.ContentRecordQWuestionEditPage />} />

    <Route path={`${NAV.CONTACT_SETTINGS}`}  element={<page.ContactsSecttingsPage />} />

    </Route>

    <Route path={`page-not-found`} element={<NotFoundPage />} />
      
    </>
  )
  );
  