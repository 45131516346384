import { useLayoutEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getTitleHeader } from "@/shared/service/get-title-header";
import { CONFIG_APP } from "@/shared/config";
import { CookieService } from "@/shared/service/cookie/CookieService";

import styles from "./ContentHeaderTitle.module.scss";

type Props = {
  isLoginPage: boolean;
  pathname: string;
};

const ContentHeaderTitle = ({ isLoginPage, pathname }: Props) => {
  const titleRef = useRef<string | null>(null);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const title = getTitleHeader(pathname);
    document.title = title || "Главная";

    titleRef.current = title || "Главная";

    if (
      pathname !== "/login" &&
      CookieService.get(CONFIG_APP.ACCESS_TOKEN_COOKIE) === ""
    ) {
      navigate("/login", { replace: true });
    }
  }, [pathname]);

  const titleValue = useMemo(
    () => getTitleHeader(pathname) || "Главная",
    [pathname]
  );

  if (isLoginPage) {
    return null;
  }

  return (
    <motion.h2
      animate={{ x: 0, opacity: 1 }}
      initial={{ x: -20, opacity: 0 }}
      transition={{
        type: "spring",
      }}
      className={styles.contentHeaderTitle}
    >
      {titleValue}
    </motion.h2>
  );
};

export { ContentHeaderTitle };
