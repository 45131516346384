import { z } from "zod";

export const EventsParamsSchema = z.object({
  page: z.string().optional(),
  limit: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
});

export const CreateEventSchema = z.object({
  name: z.string().min(1, { message: "Введиде заголовок" }),
  isImportant: z.boolean({ invalid_type_error: "Выберите тип события" }),
});

export const CreateEventFormSchema = z.object({
  name: z.string().min(1, { message: "Введиде заголовок" }),
  isImportant: z.string().min(1, { message: 'Выберите тип события' } ),
});

export type EventsParamsDto = z.infer<typeof EventsParamsSchema>;
export type CreateEventDto = z.infer<typeof CreateEventSchema>;
export type CreateEventFormDto = z.infer<typeof CreateEventFormSchema>;
