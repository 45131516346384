import { z } from "zod";

export const EventSchema = z.object({
  id: z.number(),
  count: z.number().nullable().optional(),//
  isImportant: z.number(),
  name: z.string(),
});

export const MoneySchema = z.object({
  inDays: z.array(
    z.object({
      created_at: z.string(),
      total_price: z.string(),
    })
  ),
  monthTotal: z.object({
    total_price: z.string(),
  }),
});

export const CertificatesSchema = z.object({
  inDays: z.array(
    z.object({
      created_at: z.string(),
      total_certificates: z.number(),
    })
  ),
  monthTotal: z.object({
    total_certificates: z.number(),
  }),
});

const StatisticsInitSchema = z.object({
  statisticsEventList: z.array(EventSchema),
  currentStatistic: EventSchema.nullable(),
  currentId: z.number().nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  totalCount: z.number(),
  moneyStatistics: MoneySchema.nullable(),
  certificatesStatistics: CertificatesSchema.nullable(),
});

export type CertificatesModel = z.infer<typeof CertificatesSchema>;
export type MoneyModel = z.infer<typeof MoneySchema>;
export type EventModel = z.infer<typeof EventSchema>;
export type StatisticsInitState = z.infer<typeof StatisticsInitSchema>;
