import { lazy } from "react";

export const AccountPage = lazy(() => import("./Account/Account"));
export const DashboardPage = lazy(() => import("./Dashboard/Dashboard"));
export const UserInfoPage = lazy(() => import("./User/user-info/UserInfo"));
export const UserEditPage = lazy(() => import("./User/user-edit/UserEdit"));
export const UserAddPage = lazy(() => import("./User/user-add/UserAdd"));
export const UserListPage = lazy(() => import("./User/UserList"));
export const FeedBackEditPage = lazy(() => import("./FeedBacks/FeedBackEdit"));
export const FeedBackListPage = lazy(() => import("./FeedBacks/FeedBackList"));
export const EmailTemplateListPage = lazy(
  () => import("./EmailTemplates/EmailTemplateList")
);
export const EmailHistoryListPage = lazy(
  () => import("./EmailHistory/EmailHistoryList")
);
export const EmailTemplateCreatePage = lazy(
  () => import("./EmailTemplates/EmailTemplateCreate")
);
export const EmailTemplateEditPage = lazy(
  () => import("./EmailTemplates/EmailTemplateEdit")
);
export const SmtpPage = lazy(
  () => import("./Smtp/SmtpPage")
);
export const AppLogsPage = lazy(() => import("./AppLogs/AppLogs"));
export const AppStatisticsEventsListPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsList")
);
export const AppStatisticsEventsAddPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsAdd")
);
export const AppStatisticsEventsEditPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsEdit")
);
export const LogsPage = lazy(() => import("./Logs/Logs"));
export const CommonSettingsPage = lazy(
  () => import("./CommonSettings/CommonSettings")
);
export const UpdateSystemPage = lazy(
  () => import("./UpdateSystem/UpdateSystem")
);
export const SettingsPage = lazy(() => import("./Settings/Settings"));

export const LanguageListPage = lazy(() => import("./Language/LanguageList"));
export const LanguageAddPage = lazy(() => import("./Language/LanguageAdd"));
export const LanguageEditPage = lazy(() => import("./Language/LanguageEdit"));

export const PhraseListPage = lazy(() => import("./Phrases/PhrasesList"));
export const PhraseAddPage = lazy(() => import("./Phrases/PhrasesAdd"));
export const PhraseEditPage = lazy(() => import("./Phrases/PhrasesEdit"));

export const ConfigurationKeysListPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysList")
);
export const ConfigurationKeysAddPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysAdd")
);
export const ConfigurationKeysEditPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysEdit")
);
export const LoginPage = lazy(() => import("./Login/Login"));
//------Template---------

export const CoursesListPage = lazy(() => import("./Courses/CoursesList"));
export const CoursesAddPage = lazy(
  () => import("./Courses/courses-add/CoursesAdd")
);
export const CoursesEditPage = lazy(
  () => import("./Courses/courses-edit/CoursesEdit")
);
export const CoursesInfoPage = lazy(
  () => import("./Courses/courses-info/CoursesInfo")
);

export const TaskAddPage = lazy(() => import("./Task/TaskAdd"));
export const TaskEditPage = lazy(() => import("./Task/TaskEdit"));
export const TaskAddLinkPage = lazy(() => import("./Task/task-add-link/TaskAddLink"));
export const TaskEditLinkPage = lazy(() => import("./Task/task-edit-link/TaskEditLink"));
export const TaskAddQuestionPage = lazy(() => import("./Task/task-add-question/TaskAddQuestion"));
export const TaskEditQuestionPage = lazy(() => import("./Task/task-edit-question/TaskEditQuestion"));
export const MessagesPage = lazy(() => import("./Messages/messages-list/MessagesList"));
export const DialogPage = lazy(() => import("./Messages/dialog/Dialog"));
export const OrdersListPage = lazy(() => import("./Orders/OrdersList"));
export const OrdersInfoPage = lazy(() => import("./Orders/OrdersInfo"));

export const ContentRecordListPage = lazy(() => import("./ContentRecord/content-record-list/ContentRecordList"));

export const ContentRecordRewievsAddPage = lazy(() => import("./ContentRecord/content-record-review-add/ContentRecordReviewAdd"));
export const ContentRecordRewievsEditPage = lazy(() => import("./ContentRecord/content-record-review-edit/ContentRecordReviewEdit"));

export const ContentRecordQWuestionAddPage = lazy(() => import("./ContentRecord/content-record-question-add/ContentRecordQWuestionAdd"));
export const ContentRecordQWuestionEditPage = lazy(() => import("./ContentRecord/content-record-question-edit/ContentRecordQWuestionEdit"));

export const ContactsSecttingsPage = lazy(() => import("./Contacts/ContactsSecttings"));
