import { FetchService } from "@/shared";
import { MessageRepository } from "../repository/repository";
import * as dto from "../dto";

export class MessageService {
  private readonly repository: MessageRepository;

  constructor() {
    this.repository = new MessageRepository(new FetchService());
  }

  public getMessagesList(params: dto.GetMessageParamsDto) {
    return this.repository.fetchMessagesList(params);
  }

  public fetchDialogList() {
    return this.repository.fetchDialogList();
  }

  public fetchDialogMessages(id: string) {
    return this.repository.fetchDialogMessages(id);
  }

  public fetchSendMessages(payload: dto.SendMessageInputSchemaDto) {
    return this.repository.fetchSendMessages(payload);
  }

  public fetchRemoveMessages(id: string) {
    return this.repository.fetchRemoveMessages(id);
  }

  public fetchUnreadMessageCount() {
    return this.repository.fetchUnreadMessageCount();
  }
  
}
