import { ContentCourseModel, CourseUserModel, CoursesModel } from "../schema/schema";
import { coursesStore, type coursesStoreType } from "./store";

export class CoursesAdapter {
  public store: coursesStoreType;
  constructor() {
    this.store = coursesStore;
  }

  public setCourses(coursesList: CoursesModel[]) {
    return this.store.setState({ coursesList });
  }

  public setContentUserCourses(contentCoursesList: ContentCourseModel[]) {
    return this.store.setState({ contentCoursesList });
  }

  public setUserCourses(courseUsers: CourseUserModel[]) {
    return this.store.setState({ courseUsers });
  }

  public setUserCount(usersCount: number) {
    return this.store.setState({ usersCount });
  }

  public setCourse(currentCourse: CoursesModel) {
    return this.store.setState({ currentCourse });
  }

  public setCount(currentCount: number) {
    return this.store.setState({ currentCount });
  }

  public filterCourses(id: number) {
    const coursesList = this.store.getState().coursesList;

    return this.store.setState({
      coursesList: coursesList.filter((item) => item.id !== id),
    });
  }

  public setLoading() {
    return this.store.setState({ loading: true });
  }

  public resetLoading() {
    return this.store.setState({ loading: false });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setCurrentId(id: number) {
    return this.store.setState({ currentId: id });
  }

  public resetCurrentId() {
    return this.store.setState({ currentId: null });
  }
}
