import {  PhraseModel } from "../schema/schema";
import { phraseStore, type phraseStoreType } from "./store";


export class PhraseAdapter {
  public store: phraseStoreType;
  constructor() {
    this.store = phraseStore;
  }

  public setPhraseList(phrases: PhraseModel[]) {
    return this.store.setState({ phraseList: phrases });
  }

  public setCurrentPhras(phrase: PhraseModel) {
    return this.store.setState({ currentPhrase: phrase });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().phraseList;

    return this.store.setState({ phraseList: prevList.filter(item => item.id !== Number(id)) });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setCurrentTranslate(currentTranslate: PhraseModel) {
    return this.store.setState({ currentTranslate });
  }

  public setTranslateList(translatesList: PhraseModel[]) {
    return this.store.setState({ translatesList });
  }

}


