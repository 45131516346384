import { z } from "zod";

export const UserSchema = z.object({
  id: z.number(),
  username: z.string(),
  email: z.string().email(),
  avatar: z.string().nullable(),
  gender: z.string().nullable(),
  role: z.string(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  fio: z.string(),
  phone: z.string().nullable(),
  organisation: z.string().nullable(),
  position: z.string().nullable(),
  passedCourses: z.number().nullable().optional(),
  activeCourses: z.number().nullable().optional(),
});

export const UserTableListSchema = z.object({
  id: z.number(),
  username: z.string(),
  email: z.string().email(),
  avatar: z.string().nullable(),
  role: z.string(),
  fio: z.string(),
});

const UserInitStateSchema = z.object({
  users: z.array(UserTableListSchema),
  currentUser: UserSchema.nullable(),
  currenUsertId: z.number().nullable(),
  loading: z.boolean(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  roles: z.array(z.string()),
});

export type UserTableListModel = z.infer<typeof UserTableListSchema>;
export type UserModel = z.infer<typeof UserSchema>;
export type UserInitState = z.infer<typeof UserInitStateSchema>;
