import { MessageService } from "../service/service";
import { MessageAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import * as dto from "../dto";
import { LoadingController } from "@/shared/service/loading";

export class MessageController {
  constructor(
    private readonly messageService: MessageService,
    private readonly notificationService: NotificationService,
    private readonly messageAdapter: MessageAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getMessagesList(payload: Record<string, string>) {
    this.loadingController.activeFetchLoading();
    this.messageService
      .getMessagesList(payload)
      .then((response) => {
        console.log(response);
        // if (response.status === "success" && response.data) {
        //   this.userAdapter.setUsers(response.data.users);
        //   if (response.data.currentCount) {
        //     this.userAdapter.setCurrentCount(response.data.currentCount);
        //   }
        // } else {
        //   throw response.message;
        // }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getDialogList() {
    this.loadingController.activeFetchLoading();
    this.messageService
      .fetchDialogList()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.messageAdapter.setDialogs(response.data.sort((a,b) => a.message[0].viewed - b.message[0].viewed));
          const newMessageCount = response.data.filter(
            (item) => !item.message[0].viewed && item.user?.id
          ).length;
          this.messageAdapter.setUnreadCount(newMessageCount);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getDialogMessages(id: string) {
    this.messageService
      .fetchDialogMessages(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.messageAdapter.setMessages(response.data.messages.data);
          this.getDialogList();
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      );
  }

  public sendMessage(payload: dto.SendMessageInputSchemaDto) {
    return new Promise((resolve) => {
      this.messageAdapter.setLoadingSubmit();
      this.messageService
        .fetchSendMessages(payload)
        .then((response) => {
          console.log(response.data?.message);
          if (response.status === "success" && response.data) {
          } else {
            throw response.message;
          }
          resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.messageAdapter.resetLoadingSubmit());
    });
  }

  public removeMessage(id: string, userId: string) {
    return new Promise((resolve) => {
      this.messageAdapter.setLoadingSubmit();
      this.messageService
        .fetchRemoveMessages(id)
        .then((response) => {
          // console.log(response);
          if (response.status === "success" && response.data) {
            this.getDialogMessages(userId);
          } else {
            throw response.message;
          }
          resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.messageAdapter.resetLoadingSubmit());
    });
  }

  public getUnreadMessageCount() {
    return new Promise((resolve) => {
      this.messageService
        .fetchUnreadMessageCount()
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.messageAdapter.setUnreadCount(response.data.messages);
          } else {
            throw response.message;
          }
          resolve(response.status);
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        );
    });
  }
}

export const messageController = new MessageController(
  new MessageService(),
  new NotificationService(),
  new MessageAdapter(),
  new LoadingController()
);
