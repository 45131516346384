import { FetchService } from "@/shared";
import { CourseRepository } from "../repository/repository";
import { AddUserCoursePayloadDto } from "../dto";


export class CoursesService {
  private readonly repository: CourseRepository;

  constructor() {
    this.repository = new CourseRepository(new FetchService());
  }

  public getAllCouses(payload: Record<string, string>) {
    return this.repository.getCoursesList(payload);
  }

  public getCourse(id : string) {
    return this.repository.getCourse(id);
  }

  public removeCouses(id: string) {
    return this.repository.removeCourses(id);
  }

  public addCourses(payload: FormData) {
    return this.repository.addCourses(payload);
  }

  public addUserCourse(payload: AddUserCoursePayloadDto) {
    return this.repository.addUserCourse(payload);
  }

  public editCouses(payload: { id: string, payload: FormData }) {
    return this.repository.editCourses(payload);
  }

  public fetchGetUsersCourses(id: string, params: Record<string, string>) {
    return this.repository.fetchGetUsersCourses(id, params);
  }

  public fetchUserContentCourses(id: string) {
    return this.repository.fetchUserContentCourses(id);
  }

}
