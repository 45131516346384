import { systemStore, systemStoreType } from "./store";

export class SystemAdapter {
  public store: systemStoreType
  constructor() {
    this.store = systemStore;
  }

  public setZipName(name: string) {
    return this.store.setState({ zipName: name });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
