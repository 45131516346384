import { createStore } from "../../create-store";
import type { LayoutInitState } from "../types";

const initState: LayoutInitState = {
  sideBarMenuState: false,
};

export const layoutStore = createStore({
  initState,
  persistName: 'layout-store',
});
