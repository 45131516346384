import { DialogSchemaModel, MessageModel } from "../schema/schema";
import { messageStore, type messageStoreType } from "./store";


export class MessageAdapter {
  public store: messageStoreType
  constructor() {
    this.store = messageStore;
  }

  public setDialogs(dialogs: DialogSchemaModel[]) {
    return this.store.setState({ dialogs });
  }

  public setMessages(messages: MessageModel[]) {
    return this.store.setState({ messages });
  }

  public setUnreadCount(unreadCount: number) {
    return this.store.setState({ unreadCount });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSubmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setNewDialogCount(unreadCount: number) {
    return this.store.setState({ unreadCount });
  }

}


