import { createResponseSchema } from "@/shared";
import { z } from "zod";
import { DialogSchema, MessageSchema } from "../schema/schema";

export const DialogListResponseDto = createResponseSchema(
  z.array(DialogSchema)
);

export const MessagesListResponseDto = createResponseSchema(
  z.object({
    messages: z.object({
      data: z.array(MessageSchema),
    }),
  })
);

export const SendMessageResponseDto = createResponseSchema(
  z.object({
    message: z.object({
      created_at: z.string(),
      date: z.string(),
      deletedForUser: z.unknown().nullable(),
      id: z.number(),
      message: z.string(),
      receiver_id: z.number(),
      user_id: z.number(),
      viewed: z.number(),
    }),
  })
  );

  export const UnreadCoutnResponseDto = createResponseSchema(
    z.object({
      messages: z.number(),
    })
  );

// export const RemoveMessageResponseDto = createResponseSchema(
//   z.object({message: z.string()})
// );
