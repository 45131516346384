import { z } from "zod";

export const SmtpSchema = z.object({
  smtpHost: z.string(),
  smtpPassword: z.string(),
  smtpPort: z.string(),
  smtpUsername: z.string(),
  secure: z.number(),
});

const SmtpInitStateSchema = z.object({
  smtp: SmtpSchema.nullable(),
  loadingSubmit: z.boolean(),
});

export type SmtpModel = z.infer<typeof SmtpSchema>;
export type SmtpInitState = z.infer<typeof SmtpInitStateSchema>;
