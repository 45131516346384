import { createStore } from "@/shared/service/create-store";
import { PhraseInitState } from "../schema/schema";

const initState: PhraseInitState = {
  phraseList: [],
  currentPhrase: null,
  loadingSubmit: false,
  currentCount: 0,
  currentTranslate: null,
  translatesList: [],
};

export const phraseStore = createStore({ initState });

export type phraseStoreType = typeof phraseStore;
