import { UserSchema } from "@/entities/users";
import { createResponseSchema } from "@/shared";
import { z } from "zod";

const ProfileResponseSchema = z.object({
  user: UserSchema,
});

const TokenSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
})

const AuthorizationResponseSchema = z.object({
  user: UserSchema,
  tokens: TokenSchema,
});

export const RefreshTokenResponseSchema = z.object({
  tokens: TokenSchema,
});

export const ProfileResponseDto = createResponseSchema(ProfileResponseSchema);
export const AuthorizationResponseDto = createResponseSchema(AuthorizationResponseSchema);
export const RefreshTokenResponseDto = createResponseSchema(RefreshTokenResponseSchema);
