import { UserSchema } from "@/entities/users";
import { z } from "zod";

const AuthInitStateSchema = z.object({
  profile: UserSchema.nullable(),
  authorization: z.boolean(),
  loading: z.boolean(),
});

export type AuthInitState = z.infer<typeof AuthInitStateSchema>;
