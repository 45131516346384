import { FetchService } from "@/shared";
import { phraseEndpoints, type PhraseEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class PhraseRepository {
  private readonly endpoints: PhraseEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = phraseEndpoints;
  }

  public fethPhrases(params: dto.GetPhasesParamsDto) {
    const url = this.endpoints.getList;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.PhrasesResponseDto.parseAsync(data));
  }

  public fethPhrase(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.PhraseResponseDto.parseAsync(data));
  }

  public fetchCreatePhrase(payload: dto.CreatePhraseDto) {
    const url = this.endpoints.create;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.PhraseResponseDto.parseAsync(data));
  }

  public fethEditPhrase({
    id,
    payload,
  }: {
    id: string;
    payload: dto.EditPhaseDto;
  }) {
    const url = this.endpoints.edit(id);

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.PhraseResponseDto.parseAsync(data));
  }

  public fethRemovePhrase(id: string) {
    const url = this.endpoints.remove(id);

    return this.fethService
      .delete({ url })
      .then((data) => dto.PhraseRemoveResponseDto.parseAsync(data));
  }

  public fethTranslate(payload: dto.TranslatePayloadSchemaDto) {
    const url = this.endpoints.translate;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.TranslateResponseSchemaDto.parseAsync(data));
  }

  public fethTranslates(payload: dto.TranslatesPayloadSchemaDto) {
    const url = this.endpoints.translates;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.TranslatesResponseSchemaDto.parseAsync(data));
  }
}
