import { SectionsService } from "../service/service";
import { SectionsAdapter } from "../store/adapter";
import { LoadingController } from "@/shared/service/loading";
import { NotificationService } from "@/shared";
import * as dto from "../dto";

export class SectionsController {
  constructor(
    private readonly sectionsService: SectionsService,
    private readonly notificationService: NotificationService,
    private readonly sectionsAdapter: SectionsAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public setCurrentId(id: number) {
    return this.sectionsAdapter.setCurrentId(id);
  }

  public resetCurrentId() {
    return this.sectionsAdapter.resetCurrentId();
  }

  public createSection(
    payload: dto.CreateSectioDto
  ): Promise<{ status: string; id: number | null }> {
    return new Promise((resolve) => {
      this.sectionsAdapter.setLoadingSubmit();
      this.sectionsService
        .createSection(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message:
                "Отлично! Задание добавлено к курсу. Добавьте к заданию ссылки на видео и вопросы.",
            });
          } else {
            throw response.message;
          }
          return resolve({
            status: response.status,
            id: response.data.section.id,
          });
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.sectionsAdapter.resetLoadingSubmit());
    });
  }

  public editSection(payload: {
    id: string;
    payload: dto.EditSectioDto;
  }): Promise<{ status: string; id: number | null }> {
    return new Promise((resolve) => {
      this.sectionsAdapter.setLoadingSubmit();
      this.sectionsService
        .editSection(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.notificationService.activeNotification({
              status: "success",
              message: "Изменения успешно сохранились",
            });
          } else {
            throw response.message;
          }
          return resolve({
            status: response.status,
            id: response.data.section.id,
          });
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.sectionsAdapter.resetLoadingSubmit());
    });
  }

  public removeSection(
    id: string
  ): Promise<{ status: string; id: number | null }> {
    return new Promise((resolve) => {
      this.sectionsAdapter.setLoadingSubmit();
      this.sectionsService
        .removeSection(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.sectionsAdapter.filterCourses(Number(id));
            this.notificationService.activeNotification({
              status: "success",
              message: "Задание успешно удалено",
            });
          } else {
            throw response.message;
          }
          return resolve({ status: response.status, id: null });
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.sectionsAdapter.resetLoadingSubmit());
    });
  }

  public getSections(
    id: string
  ): Promise<{ status: string; id: number | null }> {
    return new Promise((resolve) => {
      this.loadingController.activeFetchLoading();
      this.sectionsService
        .getSections(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.sectionsAdapter.setSections(response.data.sections);
          } else {
            throw response.message;
          }
          return resolve({ status: response.status, id: null });
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.loadingController.resetFethLoading());
    });
  }

  public getCurrentSection(
    id: string
  ): Promise<{ status: string; id: number | null }> {
    return new Promise((resolve) => {
      this.loadingController.activeFetchLoading();
      this.sectionsService
        .getCurrentSection(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.sectionsAdapter.setSection(response.data.section);
          } else {
            throw response.message;
          }
          return resolve({ status: response.status, id: null });
        })
        .catch(
          (error) => (
            console.error(error),
            this.notificationService.activeNotification({
              status: "error",
              message: `Ошибка: ${error}`,
            })
          )
        )
        .finally(() => this.loadingController.resetFethLoading());
    });
  }
}

export const sectionsController = new SectionsController(
  new SectionsService(),
  new NotificationService(),
  new SectionsAdapter(),
  new LoadingController()
);
