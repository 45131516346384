import { createResponseSchema } from "@/shared";
import { EventSchema } from "../schema/schema";
import { z } from "zod";

const EventsResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  eventsStatistics: z.array(EventSchema),
  statisticsByDay: z.array(z.unknown()).optional(),
});

const MoneyResponseSchema = z.object({
  inDays: z.array(
    z.object({
      created_at: z.string(),
      total_price: z.string(),
    })
  ),
  monthTotal: z.object({
    total_price: z.string(),
  }),
});
const CertificatesResponseSchema = z.object({
  inDays: z.array(
    z.object({
      created_at: z.string(),
      total_certificates: z.number(),
    })
  ),
  monthTotal: z.object({
    total_certificates: z.number(),
  }),
});

const EventResponseSchema = z.object({
  event: EventSchema,
});

const EventRemoveResponseSchema = z.object({
  message: z.string(),
});

export const CertificatesResponseDto = createResponseSchema(CertificatesResponseSchema);
export const MoneyResponseDto = createResponseSchema(MoneyResponseSchema);
export const EventsResponseDto = createResponseSchema(EventsResponseSchema);
export const EventResponseDto = createResponseSchema(EventResponseSchema);
export const EventRemoveResponseDto = createResponseSchema(
  EventRemoveResponseSchema
);
