import { z } from "zod";

export const GetEmailsParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
  id: z.string().optional(),
  key: z.string().optional(),
  email: z.string().optional(),
  status: z.string().optional(),
});

export const SendEmailSchema = z.object({
  key: z.string(),
  id: z.string().nullable(),
});

export type GetEmailsParamsDto = z.infer<typeof GetEmailsParamsSchema>;
export type SendEmailDto = z.infer<typeof SendEmailSchema>;
