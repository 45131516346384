import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { MenuItem } from "../menu-item/MenuItem";
import { authStore, feedbackController, feedbackStore } from "@/entities";
import { messageController, messageStore } from "@/entities/message";
import { Avatar } from "@/shared/ui/avatar/Avatar";
import { CONFIG_APP } from "@/shared/config";
import { menuSystem } from "@/shared/constants/menu-side-bar";
import { layoutController, layoutStore } from "@/shared";
import { orderStore, ordersController } from "@/entities/orders";

import styles from "./LayoutAside.module.scss";

const LayoutAside = () => {
  const [currentItemMenu, setCurrentItemMenu] = useState<string>("");
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const isOpen = layoutStore((state) => state.sideBarMenuState);
  const user = authStore((state) => state.profile);
  const unreadMessageCount = messageStore((state) => state.unreadCount);
  const unreadOrdersCount = orderStore((state) => state.newOrderCount);
  const unreadFeedbackCount = feedbackStore((state) => state.newFeedbackCount);

  const getNotificationSideMenu = async () => {
    await ordersController.getOrderNewCount();
    await feedbackController.getFeedbackNewCount();
    if (pathname !== "/dashboard" && pathname !== "/messages") {
      await messageController.getDialogList();
    }
  };

  useEffect(() => {
    getNotificationSideMenu();
  }, []);

  const handleNavigationMenu = useCallback(
    (href: string | undefined) => {
      if (typeof href === "undefined") return;

      layoutController.hideSideMenu();

      navigation(href);
    },
    [isOpen]
  );

  const handleActiveMenu = useCallback(
    ({ value, path }: { value: string; path: string | undefined }) => {
      if (value === currentItemMenu) {
        setCurrentItemMenu(() => "");
      } else {
        setCurrentItemMenu(() => value);
      }

      if (path) {
        handleNavigationMenu(path);
      }
    },
    [currentItemMenu]
  );

  const activePath = useMemo(() => pathname, [pathname]);

  const listVariants = {
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      x: 0,
      transition: {
        delay: i * 0.03,
        type: "spring",
      },
    }),
    hidden: { opacity: 0, y: 3, x: 0 },
  };

  return (
    <>
      {isOpen && (
        <div
          onClick={() => layoutController.hideSideMenu()}
          className={styles.shadowContent}
        ></div>
      )}
      <aside
        className={
          isOpen ? `${styles.root} ${styles.mobileSideOpen}` : styles.root
        }
      >
        <header className={styles.asideHeader}>
          <picture className={styles.asideHeaderContent}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigationMenu("/account")}
            >
              <Avatar src={`${CONFIG_APP.PATH_UPLOADS_PHOTO}${user?.avatar}`} />
            </div>
            <h5>{user?.username || "Admin"}</h5>
          </picture>
        </header>

        <nav className={styles.navlist}>
          <ul className={styles.menuList}>
            {menuSystem.map((menu, index) => (
              <motion.div
                variants={listVariants}
                initial="hidden"
                animate="visible"
                custom={index}
                key={menu.title}
              >
              
              <MenuItem
                newNotifications={{
                  feedback: unreadFeedbackCount ? unreadFeedbackCount : 0,
                  orders: unreadOrdersCount ? unreadOrdersCount : 0,
                  messages: unreadMessageCount ? unreadMessageCount : 0,
                }}
                onClicNavChildren={handleNavigationMenu}
                activePath={activePath}
                onClick={handleActiveMenu}
                isOpen={currentItemMenu === menu.title}
                key={menu.title}
                icon={menu.icon}
                title={menu.title}
                href={menu.href}
                list={menu.list}
              />
                </motion.div>
            ))}
          </ul>
        </nav>

        <p className={styles.systemInfo}>
          v{CONFIG_APP.REACT_APP_VERSION} {CONFIG_APP.REACT_APP_STATUS}
        </p>
      </aside>
    </>
  );
};

export { LayoutAside };
