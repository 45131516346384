import { FetchService } from "@/shared";
import { emailEndpoints, type EmailEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class EmailRepository {
  private readonly endpoints: EmailEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = emailEndpoints;
  }

  public fetchEmailHistorys(params: dto.GetEmailsParamsDto) {
    const url = this.endpoints.getHistorys;

    return this.fethService.get({ url, params })
    .then((data) => dto.EmailsResponseDto.parseAsync(data));
  }
  
  public fetchRemoveEmails() {
    const url = this.endpoints.removeHistory;
    
    return this.fethService.delete({ url })
    .then((data) => dto.EmailRemoveResponseDto.parseAsync(data));
  }
  
  public fetchSendUserEmail(payload: dto.SendEmailDto) {
    const url = this.endpoints.sendEmail(String(payload.id));
    
    return this.fethService.post({ url, payload })
    .then((data) => dto.SendEmailResponseDto.parseAsync(data));
  }

  public fetchSendAllUserEmail(payload: dto.SendEmailDto) {
    const url = this.endpoints.sendAllUser;

    return this.fethService.post({
      url,
      payload,
    })
    .then((data) => dto.SendAllUserEmailResponseDto.parseAsync(data));
  }
}
