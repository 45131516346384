import { FrontendLogModel, LogModel } from "../schema/schema";
import { logsStore, logsStoreType } from "./store";


export class LogsAdapter {
  public store: logsStoreType
  constructor() {
    this.store = logsStore;
  }

  public setFrontendLogsList(logs: FrontendLogModel[]) {
    return this.store.setState({ frontendLogsList: logs });
  }

  public setFrontendLogTotalCount(count: number) {
    return this.store.setState({ totalFrontendLogs: count });
  }

  public resetFrontendLogs() {
    return this.store.setState({ frontendLogsList: [] });
  }

  public setLogsList(logs: LogModel[]) {
    return this.store.setState({ LogsList: logs });
  }

  public setLogsTotalCount(count: number) {
    return this.store.setState({ totalLogs: count });
  }

  public resetLogs() {
    return this.store.setState({ LogsList: [] });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

}
