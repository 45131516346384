export const NAV = {
  ACCOUNT: "account",
  DASHBOARD: "dashboard",
  USER_INFO: "users/info/:id",
  USER_EDIT: "users/edit/:id",
  USER_ADD: "users/add",
  USER_LIST: "users",
  FEEDBACK_EDIT: "feedbacks/edit/:id",
  FEEDBACK_LIST: "feedbacks",
  EMAIL_TEMPLATES: "email-templates",
  EMAIL_SMTP: "email/smtp",
  EMAIL_HISTORY: "email-history",
  EMAIL_CREATE: "email-templates/create",
  EMAIL_EDIT: "email-templates/edit/:id",
  LOGS_LIST: "app-logs",
  APP_STATISTICS_EVENTS: "events",
  APP_STATISTICS_ADD: "events/add",
  APP_STATISTICS_EDIT: "events/edit/:id",
  LOGS: "logs",
  COMMON_SETTINGS: "common-settings",
  UPDATE_SYSTEM: "update-system",
  SETTINGS: "settings",
  LANGUAGE_LIST: "language/list",
  LANGUAGE_ADD: "language/add",
  LANGUAGE_EDIT: "language/edit/:id",
  PHRASE_LIST: "phrases/:languageId",
  PHRASE_ADD: "phrases/add/:languageId",
  PHRASE_EDIT: "phrases/edit/:id",
  CONFIGURATION_KEYS: "configuration/keys",
  CONFIGURATION_KEYS_ADD: "configuration/keys/add",
  CONFIGURATION_KEYS_EDIT: "configuration/keys/edit/:id",
  LOGIN: "login",
  MAIN: "/",
  NO_PAGE: "*",
  //------Template---------

  COURSES_LIST: "courses",
  COURSES_ADD: "courses/add",
  COURSES_EDIT: "courses/edit/:id",
  COURSES_INFO: "courses/info/:id",
  
  TASK_ADD: "tasks/add/:id",
  TASK_EDIT: "tasks/edit/:id",
  TASK_ADD_LINK: "tasks/add-link/:id",
  TASK_EDIT_LINK: "tasks/edit-link/:id/link/:linkId",
  TASK_ADD_QUESTION: "tasks/add-question/:id",
  TASK_EDIT_QUESTION: "tasks/edit-question/:id/question/:questionId",
  MESSAGES_LIST: "messages",
  DIALOG: "messages/:id",
  ORDERS_LIST: "orders",
  ORDERS_INFO: "orders/info/:id",
  CONTENT_RECORD_LIST: "content-record",
  CONTENT_RECORD_EDIT: "content-record/edit/:id",

  CONTENT_RECORD_ADD: "content-record/add",
  CONTENT_RECORD_REVIEWS_ADD: "content-record/reviews/add",
  CONTENT_RECORD_REVIEWS_EDIT: "content-record/reviews/edit/:id",
  CONTENT_RECORD_QUESTION_ADD: "content-record/question/add",
  CONTENT_RECORD_QUESTION_EDIT: "content-record/question/edit/:id",

  CONTACT_SETTINGS: "contact-settings",
};
