import { z } from "zod";

export const GetLanguagesParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
});

export const EditLanguageSchema = z.object({
  name: z.string().min(1, { message: "Введите название" }),
  isMain: z.boolean(),
});

export const CreateLanguageSchema = EditLanguageSchema.extend({});


export type GetLanguagesParamsDto = z.infer<typeof GetLanguagesParamsSchema>;
export type CreateLanguagePayload = z.infer<typeof CreateLanguageSchema>;
export type EditLanguageDto = z.infer<typeof EditLanguageSchema>;
