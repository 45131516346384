import { createResponseSchema } from "@/shared";
import { z } from "zod";

const BackendZipNameResponseSchema = z.object({
  backend: z.string(),
});

const BackendImportFileResponseSchema = z.object({
  message: z.string(),
});

export const BackendZipNameResponseDto = createResponseSchema(BackendZipNameResponseSchema);
export const BackendImportFileResponseSchemaDto = createResponseSchema(BackendImportFileResponseSchema);
