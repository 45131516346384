import { EmailModel } from "../schema/schema";
import { emailStore, type emailStoreType } from "./store";

export class EmailAdapter {
  public store: emailStoreType;
  constructor() {
    this.store = emailStore;
  }

  public setEmailsList(emails: EmailModel[]) {
    return this.store.setState({ emailList: emails });
  }

  public resetEmails() {
    return this.store.setState({ emailList: [] });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ totalCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
