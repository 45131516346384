export type TaskEndpointsType = {
  getList: (id: string) => string;
    getQuestion: (id: string) => string;
    createQuestion: (id: string) => string;
    editQuestion: (id: string) => string;
    createVideoLink: (id: string) => string;
    editVideoLink: (id: string) => string;
    remove: (id: string) => string;
};

export const taskEndpoints = {
  getList: (id: string) => `tasks/section/${id}`,
  getQuestion: (id: string) => `tasks/section/question/${id}`, // получение вопроса с ответами
  createQuestion: (id: string) => `tasks/section/${id}/question`,// создание вопроса с ответами
  editQuestion: (id: string) => `tasks/section/question/${id}`,// редактирование вопроса с ответами
  createVideoLink: (id: string) => `tasks/section/${id}/video`,// создание видео
  editVideoLink: (id: string) => `tasks/section/video/${id}`,// редактирование видео
  remove: (id: string) => `tasks/${id}`,// удалить задачу
};
