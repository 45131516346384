import { FetchService } from "@/shared";
import { coursesEndpoints, type CoursesEndpointsType } from "./endpoints";
import * as dto from "../dto";

export class CourseRepository {
  private readonly endpoints: CoursesEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = coursesEndpoints;
  }

  public getCoursesList(params: Record<string, string>) {
    const url = this.endpoints.getAll;

    return this.fethService
      .get({ url, params })
      .then((data) => dto.CoursesResponseDto.parseAsync(data));
  }

  public getCourse(id: string) {
    const url = this.endpoints.getInfo(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.CourseResponseDto.parseAsync(data));
  }

  public removeCourses(id: string) {
    const url = this.endpoints.remove(id);

    return this.fethService
      .delete({ url })
      .then((data) => dto.CourseRemoveResponseDto.parseAsync(data));
  }

  public addCourses(payload: FormData) {
    const url = this.endpoints.create;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.CourseResponseDto.parseAsync(data));
  }

  public editCourses({ id, payload }: { id: string; payload: FormData }) {
    const url = this.endpoints.edit(id);

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.CourseResponseDto.parseAsync(data));
  }

  public fetchGetUsersCourses(id: string, params: Record<string, string>) {
    const url = this.endpoints.getCoursesUsers(id);

    return this.fethService
      .get({ url, params })
      .then((data) => dto.CourseUsersResponseDto.parseAsync(data));
  }

  public addUserCourse(payload: dto.AddUserCoursePayloadDto) {
    const url = this.endpoints.addUserCourse;

    return this.fethService
      .put({ url, payload })
      .then((data) => dto.AddUserCourseResponseDto.parseAsync(data));
  }

  public fetchUserContentCourses(userId: string) {
    const url = this.endpoints.contentCourses(userId);

    return this.fethService.get({ url })
    .then(data => dto.UserContentCoursesResponseDto.parseAsync(data));
  }
}

