import AvatarMU from "@mui/material/Avatar";

type Props = {
  link?: any;
  src: string | undefined;
  width?: number;
  height?: number;
};

const Avatar = ({ src, height = 64, width = 64 }: Props) => {
  return (
    <>
      <AvatarMU
        alt="Avatar img"
        src={src}
        sx={{
          width: width,
          height: height,
        }}
      />
    </>
  );
};

export { Avatar };
