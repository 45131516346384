import { createStore } from "@/shared/service/create-store";
import { CoursesInitState } from "../schema/schema";

const initState: CoursesInitState = {
  coursesList: [],
  currentCourse: null,
  currentId: null,
  currentCount: 0,
  loading: false,
  loadingSubmit: false,
  courseUsers: [],
  usersCount: 0,
  contentCoursesList: [],
};

export const coursesStore = createStore({ initState });

export type coursesStoreType = typeof coursesStore;