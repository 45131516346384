export type CoursesEndpointsType = {
  create: string;
  getAll: string;
  getInfo: (id: string) => string;
  edit: (id: string) => string;
  remove: (id: string) => string;
  getCoursesUsers: (id: string) => string;
  addUserCourse: string;
  contentCourses: (id: string) => string;
};

export const coursesEndpoints: CoursesEndpointsType = {
  create: "courses",
  getAll: "courses",
  getCoursesUsers: (id: string) => `content/course/${id}/all-users-courses`,
  getInfo: (id: string) => `courses/${id}`,
  edit: (id: string) => `courses/${id}`,
  remove: (id: string) => `courses/${id}`,
  addUserCourse: 'admin/users/add-course',
  contentCourses: (userId: string) => `content/courses/${userId}`,
};
