import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { OrderSchema } from "../schema/schema";

const OrdersResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  orders: z.object({
    data: z.array(OrderSchema),
    pagination: z.object({
      currentPage: z.number().nullable().optional(),
      from: z.number().nullable().optional(),
      lastPage: z.number().nullable().optional(),
      nextPage: z.number().nullable().optional(),
      perPage: z.number().nullable().optional(),
      prevPage: z.number().nullable().optional(),
      to: z.number().nullable().optional(),
      total: z.number().nullable().optional(),
    }),
  }),
});

const OrderResponseSchema = z.object({
  order: OrderSchema,
});



export const NewOrdersCountResponseDto = createResponseSchema(z.object({
  newOrders: z.number(),
}));
export const OrdersResponseDto = createResponseSchema(OrdersResponseSchema);
export const OrderResponseDto = createResponseSchema(OrderResponseSchema);
export const OrderRemoveResponseDto = createResponseSchema(
  z.object({message: z.string()}),
);
