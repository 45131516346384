import { layoutStore } from "./store";

export class LayoutAdapter {
  isOpen: boolean = false;
  constructor() {}

  public setSideMenuOpen() {
    return layoutStore.setState({ sideBarMenuState: true });
  }

  public setSideMenuClose() {
    return layoutStore.setState({ sideBarMenuState: false });
  }
}
