import { createStore } from "@/shared/service/create-store";
import { OrdersInitState } from "../schema/schema";

const initState: OrdersInitState = {
  ordersList: [],
  currentOrder: null,
  loadingSubmit: false,
  totalCount: 0,
  newOrderCount: null,
};


export const orderStore = createStore({ initState });

export type orderStoreType = typeof orderStore;
