export type SectionsEndpointsType = {
  createSection: string;
  getSections: string;
  getSection: (id: string) => string;
  editSection: (id: string) => string;
  removeSection: (id: string) => string;
};

export const sectionsEndpoints: SectionsEndpointsType = {
  createSection: "sections",
  getSections: "sections",
  getSection: (id: string) => `sections/${id}`,
  editSection: (id: string) => `sections/${id}`,
  removeSection: (id: string) => `sections/${id}`,
};
