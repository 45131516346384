import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { LanguagesSchema } from "../schema/schema";

const LanguagesResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  languages: z.array(LanguagesSchema),
});

const LanguageResponseSchema = z.object({
  language: LanguagesSchema,
});

const LanguageRemoveResponseSchema = z.object({
  message: z.string(),
});

export const LanguagesResponseDto = createResponseSchema(
  LanguagesResponseSchema
);
export const LanguageResponseDto = createResponseSchema(
  LanguageResponseSchema
);
export const LanguageRemoveResponseDto = createResponseSchema(
  LanguageRemoveResponseSchema
);
