import { z } from "zod";

export const EditSectioSchema = z.object({
  name: z.string().min(1, { message: "Введите заголовок" }),
  description: z.string().min(1, { message: "Введите описание" }),
});

export const CreateSectioSchema = EditSectioSchema.extend({
  course_id: z.number(),
});

export type CreateSectioDto = z.infer<typeof CreateSectioSchema>;
export type EditSectioDto = z.infer<typeof EditSectioSchema>;
