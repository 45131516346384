export const METODATA_TITLES: Record<string, string> = {
  "/dashboard": "Дашборд",
  // "/dashboard": "Актуальные обращения",
  "/users": "Пользователи",
  "/users/add": "Создать нового пользователя",
  "/feedbacks": "Обратная связь",
  "/email-templates": "Шаблоны электронной почты",
  "/email/smtp": "Настройка электронной почты",
  "/email-templates/create": "Создание шаблона электронной почты",
  "/email-history": "История рассылки",
  "/settings": "Настройки",
  "/configuration/keys": "Конфигуратор ключей",
  "/configuration/keys/add": "Создайте новый конфигурационный ключ",
  "/app-logs": "Логи приложения",
  "/logs": "Все логи",
  "/app-statistics": "Статистика заявок",
  "/events": "События",
  "/events/add": "Добавить событие",
  "/common-settings": "Общие настройки",
  "/update-system": "Обновление системы",
  "/login": "Авторизация",
  "/account": "Профиль",
  "/language/list": "Языки",
  "/language/add": "Добавить язык",
  //----template--------

  "/courses": "Курсы",
  "/courses/add": "Создание курса",
  "/messages": "Сообщения",
  "/orders": "Список заявок",
  "/content-record": "Настройка главной",
  "/content-record/reviews/add": "Добавить отзыв",
  "/content-record/question/add": "Добавить вопрос",
  "/contact-settings": "Контакты",
};

export const METODATA_TITLES_DINAMIC: Record<string, string> = {
  "/users/info/": "Информация пользователя",
  "/users/edit/": "Редактировать пользователя",
  "/feedbacks/edit/": "Редактировать отзыв",
  "/email-templates/edit/": "Обновление шаблона электронной почты",
  "/events/edit/": "Редактировать событие",
  "/configuration/keys/edit/": "Редактировать",
  "/language/edit/": "Редактировать язык",
  "/phrases/edit/": "Редактировать перевод",
  "/phrases/add/": "Добавить перевод",
  "/phrases/": "Переводы фраз",
  //----template--------

  "/courses/edit/": "Редактировать курс",
  "/courses/info/": "Информация курса",
  "/tasks/add/": "Добавить задание",
  "/tasks/edit/": "Редактировать задание",
  "/tasks/add-link/": "Добавить ссылку",
  "/tasks/edit-link/": "Редактировать ссылку",
  "/tasks/add-question/": "Добавить вопрос",
  "/tasks/edit-question/": "Редактировать вопрос",
  "/messages/": "Сообщения",
  "/orders/info/": "Информация",

  "/content-record/reviews/edit/": "Редактировать отзыв",
  "/content-record/question/edit/": "Редактировать вопрос",
};
