import { OrdersService } from "../service/service";
import { OrdersAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

export class OrdersController {
  constructor(
    private readonly ordersService: OrdersService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: OrdersAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getOrders(params: Record<string, string>) {
    this.loadingController.activeFetchLoading();
    this.ordersService
      .fetchOrders(params)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setOrders(response.data.orders.data);

          if (response.data.totalCount) {
            this.storeAdapter.setTotalCount(response.data.currentCount);
          }
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getOrder(id: string | undefined) {
  this.sendErrorGetID(id);
  if (!id)return;

    this.loadingController.activeFetchLoading();
    this.ordersService
      .fetchOrder(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setCurrentOrder(response.data.order);
          this.getOrderNewCount();
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        }))
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getOrderNewCount() {
    this.ordersService
      .fetchOrderNewCount()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setNewOrderCount(response.data.newOrders ? response.data.newOrders : 0)
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        }))
      )
  }

  public createOrder(payload: dto.CreateOrderPayloadDto) {
    this.storeAdapter.setLoadingSubmit();
    this.ordersService
      .fetchCreateOrder(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Заявка успешно сохранена",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public removeOrder(id: string) {
    this.sendErrorGetID(id);
    if (!id) return;

    this.storeAdapter.setLoadingSubmit();
    this.ordersService
      .fetchRemoveOrder(id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.filterById(id);
          this.getOrderNewCount();
          this.notificationService.activeNotification({
            status: "success",
            message: "Заявка успешно удалена",
          });
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: `Ошибка: ${error}`,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  private sendErrorGetID(id: string | undefined) {
    if (!id) {
      this.notificationService.activeNotification({
        status: "error",
        message: "Ошибка: ID не найден",
      });
    }
  }
}

export const ordersController = new OrdersController(
  new OrdersService(),
  new NotificationService(),
  new OrdersAdapter(),
  new LoadingController()
);
