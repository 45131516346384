import { createStore } from "@/shared/service/create-store";
import { SystemInitState } from "../schema/schema";


const initState: SystemInitState = {
  loadingSubmit: false,
  zipName: "",
};

export const systemStore = createStore({ initState });

export type systemStoreType = typeof systemStore;