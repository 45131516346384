import { SmtpService } from "../service/service";
import { SmtpAdapter } from "../store/adapter";
import { NotificationService } from "@/shared";
import { LoadingController } from "@/shared/service/loading";
import * as dto from "../dto";

class SmtpController {
  constructor(
    private readonly smtpService: SmtpService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: SmtpAdapter,
    private readonly loadingController: LoadingController
  ) {}

  public getSmtp() {
    this.loadingController.activeFetchLoading();
    this.smtpService
      .fethGetSmpt()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setSmtp(response.data.smtp);
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        }))
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public editSmtp(payload: dto.EditSmtpPayloadDto) {
    this.storeAdapter.setLoadingSubmit();
    this.smtpService
      .fethEditSmtp(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setSmtp(response.data.smtp);

          this.notificationService.activeNotification({
            status: "success",
            message: "SMTP успешно обновлен",
          });
        } else {
          throw response.message;
        }
      })
      .catch((error) => (
        console.error(error),
        this.notificationService.activeNotification({
          status: "error",
          message: `Ошибка: ${error}`,
        }))
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }
}

export const smptController = new SmtpController(
  new SmtpService(),
  new NotificationService(),
  new SmtpAdapter(),
  new LoadingController()
);
