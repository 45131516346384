import { z } from "zod";

export const CourseSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  image: z.string().nullable(),
});

export const CourseUserSchema = z.object({
  avatar: z.string().nullable(),
  course_id: z.number(),
  created_at: z.string(),
  expiration_date: z.string(),
  fio: z.string(),
  id: z.number(),
  passed: z.string(),
  phone: z.string().nullable(),
  price: z.number(),
  userId: z.number(),
  user_id: z.number(),
  username: z.string(),
  progress: z.string().nullable(),
});

export const ContentCourseSchema = z.object({
  course_id: z.number(),
  created_at: z.string(),
  description: z.string(),
  expiration_date: z.string(),
  id: z.number(),
  passed: z.number(),
  price: z.number(),
  user_id: z.number(),
  image: z.string().nullable(),
  name: z.string(),
  progress: z.string(),
  updated_at: z.string(),
})

const CoursesInitStateSchema = z.object({
  coursesList: z.array(CourseSchema),
  currentCourse: CourseSchema.nullable(),
  currentId: z.number().nullable(),
  loading: z.boolean(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
  courseUsers: z.array(CourseUserSchema),
  usersCount: z.number(),
  contentCoursesList: z.array(ContentCourseSchema),
});

export type CourseUserModel = z.infer<typeof CourseUserSchema>;
export type CoursesModel = z.infer<typeof CourseSchema>;
export type ContentCourseModel = z.infer<typeof ContentCourseSchema>;
export type CoursesInitState = z.infer<typeof CoursesInitStateSchema>;
