import { z } from "zod";
import { createResponseSchema } from "@/shared";
import { PhraseSchema } from "../schema/schema";

const PhrasesResponseSchema = z.object({
  currentCount: z.number(),
  limit: z.number(),
  page: z.number(),
  totalCount: z.number(),
  phraseTranslations: z.array(PhraseSchema),
});

const PhraseResponseSchema = z.object({
  phraseTranslation: PhraseSchema,
});

const PhraseRemoveResponseSchema = z.object({
  message: z.string(),
});

const TranslateResponseSchema = z.object({
  phraseTranslation: PhraseSchema,
});
 
const TranslatesResponseSchema = z.object({
  phraseTranslations: z.array(PhraseSchema),
});

export const PhrasesResponseDto = createResponseSchema(PhrasesResponseSchema);
export const PhraseResponseDto = createResponseSchema(PhraseResponseSchema);
export const PhraseRemoveResponseDto = createResponseSchema(
  PhraseRemoveResponseSchema
  );
export const TranslateResponseSchemaDto = createResponseSchema(TranslateResponseSchema);
export const TranslatesResponseSchemaDto = createResponseSchema(TranslatesResponseSchema);
