import { z } from "zod";

export const GetMessageParamsSchema = z.object({
  page: z.string().optional(),
  limit: z.string().optional(),
  adminId: z.string().optional(),
  viewed: z.string().optional(),
});

export const SendMessageInputSchema = z.object({
  receiver_id: z.number(),
  message: z.string().min(1, 'Введите сообщение'),
});


export type GetMessageParamsDto = z.infer<typeof GetMessageParamsSchema>;
export type SendMessageInputSchemaDto = z.infer<typeof SendMessageInputSchema>;
