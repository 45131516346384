import { z } from "zod";

export const SectionSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  course_id: z.number(),
});

const SectionInitStateSchema = z.object({
  sectionsList: z.array(SectionSchema),
  currentSections: SectionSchema.nullable(),
  currentId: z.number().nullable(),
  loadingSubmit: z.boolean(),
});

export type SectionModel = z.infer<typeof SectionSchema>;
export type SectionsInitState = z.infer<typeof SectionInitStateSchema>;
