import { QuestionModel, TaskModel } from "../schema/schema";
import { taskStore, type taskStoreType } from "./store";


export class TasksAdapter {
  public store: taskStoreType
  constructor() {
    this.store = taskStore;
  }

  public setTasks(tasks: TaskModel[]) {
    return this.store.setState({ tasksList: tasks });
  }

  public setTask(task: TaskModel) {
    return this.store.setState({ currentTask: task });
  }

  public filterById(id: string) {
    const prevList = this.store.getState().tasksList;

    return this.store.setState({ tasksList: prevList.filter(item => item.id !== Number(id)) });
  }

  public setNewTask(task: TaskModel) {
    const prevList = this.store.getState().tasksList;

    return this.store.setState({ tasksList: [...prevList, task] });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ totalCount: count });
  }

  public setCurrentCount(count: number) {
    return this.store.setState({ currentCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setCurrentQuestion(question: QuestionModel) {
    return this.store.setState({ currentQuestion: question });
  }

  public resetCurrentQuestion() {
    return this.store.setState({ currentQuestion: null });
  }

}


