import { FetchService } from "@/shared";
import { logsEndpoints, type LogsEndpointsType } from "./endpoints";
import * as dto from "../dto";


export class LogsRepository {
  private readonly endpoints: LogsEndpointsType;

  constructor(private readonly fethService: FetchService) {
    this.endpoints = logsEndpoints;
  }

  public fetchFrontendLogs(params: dto.FrongendLogsParamsDto) {
    const url = this.endpoints.getFrontendLogs;

    return this.fethService.get({ url, params })
    .then(data => dto.FrontendLogsResponseDto.parseAsync(data));
  }
  
  public fetchCreateFrontendLog(payload: dto.CreateFrontendLogsDto) {
    const url = this.endpoints.createFrontendLog;
    
    return this.fethService.post({
      url,
      payload,
    })
    .then(data => dto.CreateFrontendLogResponseDto.parseAsync(data));
  }

  public fetchRemoveFrontendLog() {
    const url = this.endpoints.removeFrontendLogs;

    return this.fethService.delete({ url })
    .then(data => dto.LogsRemoveResponseDto.parseAsync(data));
  }

  public fetchLogs(params: dto.LogsParamsDto) {
    const url = this.endpoints.getLogs;

    return this.fethService.get({ url, params })
    .then(data => dto.LogsResponseDto.parseAsync(data));
  }
  
  public fetchRemoveLogs() {
    const url = this.endpoints.removeLogs;
    
    return this.fethService.delete({ url })
    .then(data => dto.LogsRemoveResponseDto.parseAsync(data));
  }
}

export const logsRepository = new LogsRepository(new FetchService())
