import { z } from "zod";

export const LanguagesSchema = z.object({
  id: z.number(),
  name: z.string(),
  isMain: z.boolean(),
});

const LanguagesInitStateSchema = z.object({
  languagesList: z.array(LanguagesSchema),
  currentLanguage: LanguagesSchema.nullable(),
  loadingSubmit: z.boolean(),
  currentCount: z.number(),
});

export type LanguagesModel = z.infer<typeof LanguagesSchema>;
export type LanguagesInitState = z.infer<typeof LanguagesInitStateSchema>;
