import type { OrderModel } from "../schema/schema";
import { orderStore, type orderStoreType } from "./store";


export class OrdersAdapter {
  public store: orderStoreType;
  constructor() {
    this.store = orderStore;
  }

  public setOrders(ordersList: OrderModel[]) {
    return this.store.setState({ ordersList });
  }

  public setCurrentOrder(currentOrder: OrderModel) {
    return this.store.setState({ currentOrder });
  }

  public filterById(id: string) {
    const ordersList = this.store.getState().ordersList;

    return this.store.setState({ ordersList: ordersList.filter(item => item.id !== Number(id)) });
  }

  public setTotalCount(count: number) {
    return this.store.setState({ totalCount: count });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

  public setNewOrderCount(newOrderCount: number) {
    return this.store.setState({ newOrderCount });
  }
  
}


